import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import Util from '../Util';

const Contact = ({ button, children, linkTo }) => {
  const { t } = useTranslation();
  const label = t('Contacto');
  return (
    <a href={linkTo || Util.contact} className="text-gray-100" target="_blank" rel="noopener noreferrer">
      {!children && (button ? <Button>{label}</Button> : label)}
      {children}
    </a>
  );
};

export default Contact;
