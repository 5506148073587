import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.08), 0 3px 15px rgba(0, 0, 0, 0.08);
  height: 100%;
  padding: 30px 20px 30px 20px;
  transition: transform 0.2s ease-in-out !important;

  &:hover {
    transform: translateY(-10px) !important;
  }
`;

const Card = ({ className, children, style }) => (
  <Wrapper className={`rounded-lg mb-10 ${className}`} style={style}>
    {children}
  </Wrapper>
);

export default Card;
