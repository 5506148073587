import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import chroma from 'chroma-js';

import Layout from '../components/layout/Layout';
import Service from '../components/Service';
import Carousel from '../components/Carousel';
import Colors from '../styles/Colors';
import Circles from '../components/Circles';
import Cta from '../components/Cta';
import Separator from '../components/Separator';
import ItemCard from '../components/ItemCard';
import TextBlock from '../components/TextBlock';
import { useViewportAnimations } from '../hooks/hooks';

import robot from '../images/services/robot.png';

const logos = [
  'automation.png',
  'suite.png',
  'conciliac.png',
  'blueprism.png',
  'odoo.png',
  'pandas.png',
  'python.png',
  'uipath.svg',
  'pyautogui.png',
  'se.png',
  'tabula.png',
  'aws.png',
  'azure.png',
  'openpyxl.png',
];

const colors = {
  from: Colors.secondary,
  to: chroma(Colors.secondary).brighten(1),
};

const tiles = [
  {
    color: Colors.red1,
    src: robot,
    style: { backgroundSize: '200%', backgroundPosition: '0% 0%' },
  },
  {
    color: Colors.orange1,
    src: robot,
    style: {
      backgroundSize: '200%',
      backgroundPosition: 'calc(100% + 20px) calc(0% + 20px)',
    },
  },
  {
    color: Colors.violet0,
    src: robot,
    style: {
      backgroundSize: '200%',
      backgroundPosition: 'calc(0% + 30px) calc(100% + 80px)',
    },
  },
  {
    color: Colors.yellow0,
    src: robot,
    style: {
      backgroundSize: '200%',
      backgroundPosition: 'calc(100% + 40px) calc(100% + 40px)',
    },
  },
];

const Digital = () => {
  useViewportAnimations();
  const { t } = useTranslation();

  return (
    <Layout>
      <Service
        tiles={tiles}
        title={t('Transformación Digital')}
        sub={t('Lideramos el cambio de la Digitalización y Automatización')}
        colors={colors}
        description={
          <>
            <p>
              {t(
                'La transformación digital implica la digitalización y automatización de procesos y tareas, orientada a mejorar la eficiencia de recursos, optimizar los métodos de trabajo, reducir los riesgos y mejorar los servicios prestados a los clientes para generar beneficios económicos medibles y concretos.'
              )}
            </p>
            <br />
            <p>
              {t(
                'Nuestro enfoque como Partner integral ofrece soluciones para todas las necesidades que involucren una transformación digital, garantizando resultados de forma rápida y segura.'
              )}
            </p>
          </>
        }
      />
      <Separator title={t('Beneficios')} {...colors} />
      <TextBlock>
        <Trans i18nKey="digitalAdvantages" components={{ ul: <ul style={{ listStyle: 'square' }} />, li: <li /> }} />
      </TextBlock>
      <Separator title={t('Casos de Aplicación')} {...colors} />
      <div className="container mx-auto mt-10">
        <Carousel>
          {t('digitalCases', { returnObjects: true }).map((c, i) => (
            <ItemCard
              key={i}
              title={<div className="text-center">{c.title}</div>}
              content={
                <Trans
                  components={{
                    ul: <ul className="list-disc pl-10 pr-10 pt-5" style={{ minHeight: 320 }} />,
                    li: <li />,
                  }}
                >
                  {c.content}
                </Trans>
              }
              icon={c.icon}
            />
          ))}
        </Carousel>
      </div>
      <Separator title={t('Tecnologías')} {...colors} />
      <div className="vp">
        <Circles logos={logos} />
      </div>
      <Cta
        title="Innovation Discovery"
        description={t('Llená nuestro formulario para detectar oportunidades de automatización')}
        buttonLabel={t('Comenzar')}
        buttonLink="http://innovationdiscovery.calyx-cloud.com.ar/responder.php"
      />
    </Layout>
  );
};

export default Digital;
