import React, { useCallback } from 'react';
import inViewport from 'in-viewport';

export const useAnimationFrame = (callback) => {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = React.useRef();
  const previousTimeRef = React.useRef();

  const animate = useCallback((time) => {
    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;
      callback(deltaTime);
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  });

  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [animate]); // Make sure the effect runs only once
};

export const useViewportAnimations = ({ animation = 'fadeInUp' } = {}) => {
  React.useEffect(() => {
    Array.from(document.getElementsByClassName('vp')).forEach((v) => {
      v.classList.add('vp_hide');

      inViewport(v, () => {
        v.classList.remove('vp_hide');
        v.classList.add('animate__animated');
        v.classList.add(`animate__${animation}`);

        const delayClass = Array.from(v.classList).find(
          (c) => c.indexOf('delay') > -1
        );

        let delay = 2000;

        if (delayClass) {
          delay = delayClass.split('-').slice(-1)[0].replace('s', '');
          if (delay) {
            delay = parseInt(delay) * 1200;
          }
        }

        setInterval(() => {
          v.classList.remove('animate__animated');
          v.classList.remove(`animate__${animation}`);
        }, delay);
      });
    });
  }, [animation]);
};
