import React from 'react';
import Footer from './Footer';
import Header from './Header';

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main
        className="text-gray-900"
        style={{ overflowX: 'hidden', position: 'relative' }}
      >
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
