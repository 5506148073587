import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';

import Card from './Card';
import Button from './Button';
import StatsBox from './StatsBox';
import Util from '../Util';
import Colors from '../styles/Colors';

const IconWrap = ({ icon }) => {
  return (
    <div
      style={{
        justifyContent: 'center',
        display: 'flex',
        fontSize: 60,
        color: Colors.gray6,
        marginBottom: 20,
      }}
    >
      <Icon icon={icon} />
    </div>
  );
};

const ItemCard = ({ icon, className, title, description, content, metric, linkTo, onClick, color, style }) => {
  const { t } = useTranslation();
  const button = (onClick || linkTo) && (
    <div style={{ marginTop: Util.isMobile() ? 20 : 0, textAlign: 'center' }}>
      <Button size="sm" className={color} onClick={onClick}>
        {t('Más')}
      </Button>
    </div>
  );

  return (
    <Card className={`px-5 flex flex-col z-10 ${className}`} style={style}>
      {icon && <IconWrap icon={icon} />}
      {title && <p className="font-semibold text-xl text-center">{title}</p>}
      {content}
      {description && <p className="mt-2 px-3">{description}</p>}
      {metric && (
        <div className="text-center">
          <StatsBox className="text-center" primaryText={metric} />
        </div>
      )}
      {linkTo && <Link to={linkTo}>{button}</Link>}
      {onClick && button}
    </Card>
  );
};

export default ItemCard;
