import React from 'react';

const TextBlock = ({ children, ...rest }) => {
  return (
    <div {...rest} className="lg:pr-16 xl:px-40 px-10 mt-8 xl:text-2xl text-xl font-light leading-relaxed">
      {children}
    </div>
  );
};

export default TextBlock;
