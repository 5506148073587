import React, { Suspense, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Canvas, useFrame, useLoader } from 'react-three-fiber';
import { TextureLoader, RepeatWrapping, Vector3 } from 'three';
import Texture from '../images/textures/texture2.png';
import Util from '../Util';

const Wrapper = styled.div`
  position: absolute;
  right: -500px;
  top: -300px;
  opacity: ${() => (Util.isMobile() ? 0.05 : 0.1)};
  z-index: -1;
`;

const Box = (props) => {
  const [rotated, setRotated] = useState(false);
  const texture = useLoader(TextureLoader, Texture);

  // This reference will give us direct access to the mesh
  const mesh = useRef();

  // Rotate mesh every frame, this is outside of React without overhead
  useFrame(() => {
    mesh.current.rotateY(0.001);
  });

  useEffect(() => {
    if (!rotated) {
      if (mesh.current) {
        mesh.current.rotateOnAxis(new Vector3(0.5, 0, -0.01).normalize(), 0.436332);
      }

      setRotated(true);
    }
  }, [rotated]);

  if (texture) {
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    texture.repeat.x = 20;
    texture.repeat.y = 20;
  }

  return (
    <mesh position={[0, 0, 0]} ref={mesh} scale={[0.6, 0.6, 0.6]}>
      <meshStandardMaterial attach="material" map={texture} />
      <sphereBufferGeometry args={[4, 128, 128]} />
    </mesh>
  );
};

const Sphere = () => {
  return (
    <Wrapper>
      <Canvas
        style={{
          height: 1400,
          width: 1400,
        }}
      >
        <pointLight position={[10, 10, 10]} />
        <Suspense fallback={null}>
          <Box />
        </Suspense>
      </Canvas>
    </Wrapper>
  );
};

export default Sphere;
