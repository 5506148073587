import React, { useEffect } from 'react';
import styled from 'styled-components';
import mapboxgl from 'mapbox-gl';

const GlobeWrap = styled.div`
  width: 100%;
  height: ${(props) => props.dim}px;
  opacity: 0.4;
  position: absolute;
  top: 0px;
  z-index: 0;
  right: left: 0px;
  pointer-events: none;
  transform: skew(0deg, -8deg);
`;

const Map = ({ style, mapStyle = 'light', noLabels }) => {
  useEffect(() => {
    const BA = [-58.415606, -34.559175];
    const countries = [
      [-14.235, -51.9253],
      [-19.851181, -43.950628],
      [37.0902, -95.7129],
      [-9.19, -75.0152],
      [4.5709, -74.2973],
      [-35.6751, -71.543],
      [-23.4425, -58.4438],
      [23.6345, -102.5528],
      [40.4637, -3.7492],
      [-30.5595, 22.9375],
      [15.87, 100.9925],
      [35.8617, 104.1954],
      [4.2105, 101.9758],
      [20.5937, 78.9629],
      [55.3781, -3.436],
      [46.2276, 2.2137],
      [39.3999, -8.2245],
      [41.8719, 12.5674],
    ].map((d) => [BA, d.reverse()]);

    mapboxgl.accessToken =
      'pk.eyJ1Ijoibmljb3RlamVyYSIsImEiOiJTX0dITmZnIn0.RX1xqgMqyDKktm3OrGBqLA';

    const map = new mapboxgl.Map({
      container: 'globe',
      style: `mapbox://styles/mapbox/${mapStyle}-v10`,
      center: [0, 0],
      zoom: 4,
    });

    map.on('load', () => {
      var bounds = countries.reduce(function (bounds, coord) {
        return bounds.extend(coord);
      }, new mapboxgl.LngLatBounds(BA, BA));

      if (noLabels) {
        map.style.stylesheet.layers.forEach(function (layer) {
          if (layer.type === 'symbol') {
            map.removeLayer(layer.id);
          }
        });
      }

      map.fitBounds(bounds, {
        padding: {
          left: 200,
          right: 20,
          top: 100,
          bottom: 100,
        },
      });

      map.addSource('lines', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: countries.map((d) => ({
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: d,
            },
          })),
        },
      });

      map.addLayer({
        id: 'lines',
        type: 'line',
        source: 'lines',
        paint: {
          'line-width': 3,
          'line-color':
            mapStyle === 'light'
              ? 'rgba(50, 50, 150, 0.7)'
              : 'rgba(0, 255, 255, 0.5)',
          'line-dasharray': [1, 1],
        },
      });
    });
  }, [mapStyle, noLabels]);

  return (
    <div>
      <GlobeWrap id="globe" dim={window.innerHeight} style={style} />
    </div>
  );
};

export default Map;
