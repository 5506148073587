import React, { useState } from 'react';
import styled from 'styled-components';
import { useAnimationFrame } from '../hooks/hooks';
import Util from '../Util';

const SCROLL_SPEED = Util.isMobile() ? 0.6 : 0.3;
const NOISE_SPEED = 0.004;
const NOISE_AMOUNT = 5;
const CANVAS_WIDTH = 1800;

const bubbles = [
  { s: 0.7, x: 1134, y: 45 },
  { s: 0.7, x: 1620, y: 271 },
  { s: 0.7, x: 1761, y: 372 },
  { s: 0.7, x: 2499, y: 79 },
  { s: 0.7, x: 2704, y: 334 },
  { s: 0.7, x: 2271, y: 356 },
  { s: 0.7, x: 795, y: 226 },
  { s: 0.7, x: 276, y: 256 },
  { s: 0.7, x: 1210, y: 365 },
  { s: 0.7, x: 444, y: 193 },
  { s: 0.7, x: 2545, y: 387 },
  { s: 0.9, x: 1303, y: 193 },
  { s: 0.9, x: 907, y: 88 },
  { s: 0.9, x: 633, y: 320 },
  { s: 0.9, x: 323, y: 60 },
  { s: 0.9, x: 129, y: 357 },
  { s: 0.9, x: 1440, y: 342 },
  { s: 0.9, x: 1929, y: 293 },
  { s: 0.9, x: 2135, y: 198 },
  { s: 0.9, x: 2276, y: 82 },
  { s: 0.9, x: 2654, y: 182 },
  { s: 0.9, x: 2783, y: 60 },
  { x: 1519, y: 118 },
  { x: 1071, y: 233 },
  { x: 1773, y: 148 },
  { x: 2098, y: 385 },
  { x: 2423, y: 244 },
  { x: 901, y: 385 },
  { x: 624, y: 111 },
  { x: 75, y: 103 },
  { x: 413, y: 367 },
  { x: 2895, y: 271 },
  { x: 1990, y: 75 },
].sort((a, b) => {
  if (a.x < b.x) return -1;
  if (a.x > b.x) return 1;
  return 0;
});

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  z-index: 10;
`;

// eslint-disable-next-line
noise.seed(Math.floor(Math.random() * 64000));

const Bubbles = ({ logos }) => {
  const [positions, setPositions] = useState(
    bubbles.slice(0, logos.length).map((p) => ({
      ...p,
      noiseSeedX: Math.floor(Math.random() * 64000),
      noiseSeedY: Math.floor(Math.random() * 64000),
      s: p.s || 1,
    }))
  );

  useAnimationFrame(() => {
    const newPositions = positions.map((pos) => {
      const newPos = { ...pos };

      newPos.noiseSeedX += NOISE_SPEED;
      newPos.noiseSeedY += NOISE_SPEED;
      // eslint-disable-next-line
      const randomX = noise.simplex2(newPos.noiseSeedX, 0);
      // eslint-disable-next-line
      const randomY = noise.simplex2(newPos.noiseSeedY, 0);

      newPos.x -= SCROLL_SPEED;
      newPos.xWithNoise = newPos.x + randomX * NOISE_AMOUNT;
      newPos.yWithNoise = newPos.y + randomY * NOISE_AMOUNT;

      if (newPos.x < -200) {
        newPos.x = CANVAS_WIDTH;
      }

      return newPos;
    });

    setPositions(newPositions);
  });

  const style = {
    width: 152,
    height: 152,
    borderRadius: '50%',
    backgroundColor: 'white',
    boxShadow: `0 15px 35px rgba(0, 0, 0, 0.1), 0 3px 10px rgba(0, 0, 0, 0.1)`,
    backgroundPosition: 'center',
    backgroundSize: '80%',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    zIndex: 20,
  };

  return (
    <Wrapper>
      {logos.map((l, i) => {
        const { s, xWithNoise, yWithNoise } = { ...positions[i] };

        return (
          <div
            key={i}
            style={{
              transform: `translate(${xWithNoise}px, ${yWithNoise}px) scale(${s})`,
              backgroundImage: `url(${l})`,
              ...style,
            }}
          />
        );
      })}
    </Wrapper>
  );
};

// const Bubbles = () => {
//   const [count, setCount] = React.useState(0)

//   useAnimationFrame(deltaTime => {
//     // Pass on a function to the setter of the state
//     // to make sure we always have the latest state
//     setCount(prevCount => (prevCount + deltaTime * 0.01) % 100)
//   })

//   return <div>{Math.round(count)}</div>
// }

export default Bubbles;
