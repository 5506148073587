import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import chroma from 'chroma-js';
import Util from '../Util';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  flex-wrap: wrap;
  perspective: 1000px;
  height: ${() => (Util.isMobile() ? window.innerWidth : 0)}px;
`;

const Inner = styled.div`
  transform: rotateY(-6deg);
  flex: 1;
`;

const Image = styled.div`
  background-color: ${({ color }) => chroma(color || 'white').alpha(1)};
  box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1), 0 20px 55px rgba(0, 0, 0, 0.1);
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: calc(100%);
  border-radius: 4px;
  color: white;
  font-size: ${(props) => props.width - 40}px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.width}px;
  line-height: ${(props) => props.width}px;
  position: absolute;
  text-align: center;
`;

const Mosaic = ({ tiles }) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const dom = document.getElementById('wrapper');
    if (dom) {
      setWidth(dom.offsetWidth / 3 + 40);
    }
  }, []);

  if (!tiles || tiles.length !== 4) return null;

  const anim = (s) => `animate__animated animate__fadeInUp animate__delay-${s}s`;

  const delays = [1, 2, 3, 4].sort(() => 0.5 - Math.random());
  const z = [1, 2, 3, 4].sort(() => 0.5 - Math.random());

  const images = [
    {
      style: { right: width - 10, top: 0, zIndex: 1 },
    },
    {
      style: { right: 0, top: -20, zIndex: 4 },
    },
    {
      style: { right: width + 10, top: width - 20, zIndex: 2 },
    },
    {
      style: { right: 20, top: width + 20, zIndex: 3 },
    },
  ];

  return (
    <Wrapper id="wrapper" className="xl:py-0 py-16">
      <Inner>
        {images.map((i, k) => {
          const { src, icon, color, style = {} } = tiles[k];

          return (
            <Image
              key={k}
              color={color}
              className={anim(delays[k])}
              src={src}
              style={{ ...i.style, zIndex: z[k], ...style }}
              width={width}
            >
              {icon && <Icon icon={icon} style={{ margin: 'auto', height: `${width}px` }} />}
            </Image>
          );
        })}
      </Inner>
    </Wrapper>
  );
};

export default Mosaic;
