import React from 'react';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import { useViewportAnimations } from '../hooks/hooks';
import Layout from '../components/layout/Layout';
import Service from '../components/Service';
import Colors from '../styles/Colors';
import ItemCard from '../components/ItemCard';
import AppIcon from '../components/AppIcon';
import Separator from '../components/Separator';
import Carousel from '../components/Carousel';
import Cta from '../components/Cta';
import Util from '../Util';
import Deck from '../components/Deck';
import TextBlock from '../components/TextBlock';

import accounting from '../images/odoo/accounting.gif';
import builder from '../images/odoo/builder.png';
import calendar from '../images/odoo/calendar.png';
import crm from '../images/odoo/crm.gif';
import employees from '../images/odoo/employees.gif';
import expenses from '../images/odoo/expenses.gif';
import field from '../images/odoo/field.gif';
import help from '../images/odoo/help.png';
import inventory from '../images/odoo/inventory.gif';
import project from '../images/odoo/project.jpg';

import cart from '@iconify/icons-mdi/cart';
import chartLine from '@iconify/icons-mdi/chart-line';
import cloud from '@iconify/icons-mdi/cloud';
import cog from '@iconify/icons-mdi/cog-clockwise';
import credit from '@iconify/icons-mdi/credit-card';
import currency from '@iconify/icons-mdi/currency-usd';
import dropbox from '@iconify/icons-mdi/dropbox';
import earth from '@iconify/icons-mdi/earth';
import edit from '@iconify/icons-mdi/account-edit';
import email from '@iconify/icons-mdi/email-send-outline';
import handshake from '@iconify/icons-mdi/handshake';
import lifebuoy from '@iconify/icons-mdi/lifebuoy';
import puzzle from '@iconify/icons-mdi/puzzle';
import store from '@iconify/icons-mdi/store';
import tableCog from '@iconify/icons-mdi/table-cog';
import timer from '@iconify/icons-mdi/timer';
import wrench from '@iconify/icons-mdi/wrench';

const getIcons = (icons) =>
  icons.map((i) => (
    <AppIcon title={<div style={{ minHeight: '80px' }}>{i.title}</div>} icon={i.icon} color={i.color} />
  ));

const colors = {
  from: Colors.three,
  to: chroma(Colors.three).brighten(1),
};

const apps = [
  {
    title: 'Accounting',
    image: accounting,
  },
  {
    title: 'Expenses',
    image: expenses,
  },
  {
    title: 'CRM',
    image: crm,
  },
  {
    title: 'Project Management',
    image: project,
  },
  {
    title: 'Website Builder',
    image: builder,
  },
  {
    title: 'Helpdesk',
    image: help,
  },
  {
    title: 'Inventory',
    image: inventory,
  },
  {
    title: 'Field Service',
    image: field,
  },
  {
    title: 'Calendar',
    image: calendar,
  },
  {
    title: 'HR',
    image: employees,
  },
];

const items = (t) => [
  {
    title: t('AUMENTE SUS VENTAS'),
    content: (
      <div className="flex flex-wrap mt-10">
        {getIcons([
          {
            icon: handshake,
            title: t('CRM'),
            color: Colors.blue9,
          },
          {
            icon: store,
            title: t('POS'),
            color: Colors.lime9,
          },
          {
            icon: chartLine,
            title: t('Ventas'),
            color: Colors.orange9,
          },
        ])}
      </div>
    ),
  },
  {
    title: t('INTEGRE SUS SERVICIOS'),
    content: (
      <div className="flex flex-wrap mt-10">
        {getIcons([
          {
            icon: puzzle,
            title: t('Proyecto'),
            color: Colors.blue9,
          },
          {
            icon: timer,
            title: t('Hoja de Horas'),
            color: Colors.red9,
          },
          {
            icon: lifebuoy,
            title: t('Servicio de Asistencia'),
            color: Colors.pink9,
          },
        ])}
      </div>
    ),
  },
  {
    title: t('OPTIMICE SUS OPERACIONES'),
    content: (
      <div className="flex flex-wrap mt-10">
        {getIcons([
          {
            icon: dropbox,
            title: t('Inventario'),
            color: Colors.red9,
          },
          {
            icon: wrench,
            title: t('MRP'),
            color: Colors.green9,
          },
          {
            icon: credit,
            title: t('Compra'),
            color: Colors.indigo9,
          },
        ])}
      </div>
    ),
  },
  {
    title: t('CONSTRUYA SITIOS WEB IMPACTANTES'),
    content: (
      <div className="flex flex-wrap  mt-10">
        {getIcons([
          {
            icon: earth,
            title: t('Creador de Sitios Web'),
            color: Colors.orange9,
          },
          {
            icon: cart,
            title: t('Comercio Electrónico'),
            color: Colors.indigo9,
          },
        ])}
      </div>
    ),
  },
  {
    title: t('MANEJE SUS FINANZAS'),
    content: (
      <div className="flex flex-wrap  mt-10">
        {getIcons([
          {
            icon: currency,
            title: t('Facturación'),
            color: Colors.orange9,
          },
          {
            icon: tableCog,
            title: t('Contabilidad'),
            color: Colors.pink9,
          },
        ])}
      </div>
    ),
  },
  {
    title: t('AMPLIFIQUE SU MARKETING'),
    content: (
      <div className="flex flex-wrap mt-10">
        {getIcons([
          {
            icon: email,
            title: t('Marketing por E-mail'),
            color: Colors.gray9,
          },
          {
            icon: cog,
            title: t('Automatización de Marketing'),
            color: Colors.indigo9,
          },
        ])}
      </div>
    ),
  },
  {
    title: t('PERSONALICE Y DESARROLLE'),
    content: (
      <div className="flex flex-wrap  mt-10">
        {getIcons([
          {
            icon: edit,
            title: t('Studio'),
            color: Colors.pink9,
          },
          {
            icon: cloud,
            title: t('odoo.sh'),
            color: Colors.violet9,
          },
        ])}
      </div>
    ),
  },
];

const tiles = [
  {
    src: require('../images/logos/odoo.png'),
  },
  {
    color: Colors.red0,
    src: 'https://odoocdn.com/web/image/16180402/fsm_screenshot_01.gif',
    style: { backgroundSize: 'cover', backgroundPosition: '0% 0%' },
  },
  {
    color: Colors.yellow0,
    src: builder,
    style: { backgroundSize: 'cover', backgroundPosition: '0% 0%' },
  },
  {
    color: Colors.green0,
    src: 'https://odoocdn.com/openerp_website/static/src/img/2018/employees/employees_screenshot_01.gif',
    style: { backgroundSize: 'cover', backgroundPosition: '0% 0%' },
  },
];

const Erp = () => {
  useViewportAnimations();
  const { t } = useTranslation();

  return (
    <Layout>
      <Service
        tiles={tiles}
        colors={colors}
        title={t('Plataforma Integral ERP')}
        sub={t('Compañías increíbles merecen un software increíble con integraciones infinitas.')}
        description={
          <>
            <p>
              {t(
                'A través de nuestra Plataforma ERP, vinculamos e integramos todos los procesos de tu negocio. Esto permite potenciar los equipos y minimizar ineficiencias para un funcionamiento óptimo.'
              )}
            </p>
            <br />
            <p>
              {t(
                'Consolidar y centralizar el acceso a la información de los distintos equipos reduce barreras y maximiza la interoperabilidad.'
              )}
            </p>
          </>
        }
      />
      <Separator title={t('Implementación Personalizada')} {...colors} />
      <TextBlock>
        <p>
          {t(
            'Nuestros equipos altamente calificados se especializan en migrar sus procesos de negocio a nuestra plataforma ERP, ofreciendo la localización más completa y funcional del mercado.'
          )}
        </p>
        <br />
        <p>
          {t(
            'Desde la carga completa de datos, definición de los workflows e interfaces gráficas, llevamos su empresa a su mejor versión, con herramientas 100% digitales.'
          )}
        </p>
      </TextBlock>

      <div className="container mx-auto mt-10">
        <div className="pd-20">
          <Carousel double={!Util.isMobile()} style={{ width: '100% ' }}>
            {apps.map((c, i) => (
              <div>
                <h3 className="font-bold text-center text-4xl mb-5">{c.title}</h3>
                <img
                  alt={t('Implementación Personalizada')}
                  src={c.image}
                  style={{
                    borderRadius: 5,
                    boxShadow: '0 5px 35px rgba(0, 0, 0, 0.08), 0 3px 35px rgba(0, 0, 0, 0.08)',
                    marginBottom: 40,
                  }}
                />
              </div>
            ))}
          </Carousel>
        </div>
      </div>

      <Separator title={t('Ventajas Odoo')} {...colors} style={{ marginTop: 50 }} />
      <Deck i18nKey="erpAdvantages" />
      <Separator title={t('Applications')} {...colors} />
      <div className="container mx-auto">
        <Carousel>
          {items(t).map((c, i) => (
            <ItemCard key={i} title={t(c.title)} content={c.content} />
          ))}
        </Carousel>
      </div>
      <Cta title={t('Clientes Odoo')} buttonLabel={t('Acceder')} buttonLink="https://odoo.calyx-cloud.com.ar/" />
    </Layout>
  );
};

export default Erp;
