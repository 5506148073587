import React from 'react';
import Logo from './Logo';

const Circles = ({ logos }) => (
  <div className="container mx-auto items-center flex lg:flex-row flex-wrap justify-center pb-10">
    {logos.map((l) => (
      <Logo key={l} src={require(`../images/logos/${l}`)} />
    ))}
  </div>
);

export default Circles;
