import { useTranslation, Trans } from 'react-i18next';
import React from 'react';
import ItemCard from './ItemCard';
import Util from '../Util';

const Deck = ({ items, i18nKey }) => {
  const { t } = useTranslation();
  const cards = items || t(i18nKey, { returnObjects: true });
  let divider = cards.length > 4 ? cards.length / 2 : 4;

  if (Util.isMobile()) {
    divider = 1;
  }

  return (
    <div className="container mx-auto flex flex-wrap mt-10">
      {cards.map((c, i) => (
        <div style={{ padding: 10, width: `calc(100% / ${divider})` }} key={i}>
          <ItemCard
            className={`vp animate__delay-${i}s`}
            {...c}
            content={
              <div style={{ flex: '1 1 auto' }}>
                <Trans
                  components={{
                    ul: <ul className="list-disc pl-10 pr-10 pt-5" />,
                    li: <li />,
                  }}
                >
                  {c.content}
                </Trans>
              </div>
            }
          />
        </div>
      ))}
    </div>
  );
};

export default Deck;
