import React from 'react';
import TopDiagonal from './TopDiagonal';
import Mosaic from './Mosaic';

const Service = ({ tiles, title, sub, description, colors }) => {
  return (
    <>
      <TopDiagonal colors={colors} />
      <section className="xl:pt-20 pt-8 xl:mb-40 mb-60 xl:flex xl:px-40 px-8">
        <div
          style={{
            position: 'relative',
            zIndex: 10,
            flex: 1,
          }}
        >
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none animate__animated animate__fadeInUp">
            {title}
          </h1>
          {sub && (
            <h2 className="text-3xl leading-none mt-5 animate__animated animate__fadeInUp animate__delay-1s">{sub}</h2>
          )}
          <div className="mt-8 text-xl lg:text-2xl mt-6 font-light animate__animated animate__fadeInUp animate__delay-2s">
            {description}
          </div>
        </div>
        <Mosaic tiles={tiles} />
      </section>
    </>
  );
};

export default Service;
