import styled from 'styled-components';
import Util from '../Util';

const Logo = styled.div`
  background-color: white;
  background-image: url(${(props) => props.src});
  border-radius: 50%;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1), 0 3px 10px rgba(0, 0, 0, 0.1);
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
  margin: 10px;
  width: ${() => (Util.isMobile() ? '80px' : '152px')};
  height: ${() => (Util.isMobile() ? '80px' : '152px')};
  padding: ${() => (Util.isMobile() ? '60px' : '80px')};
`;

export default Logo;
