import React from 'react';
import styled from 'styled-components';
import LabelText from './LabelText';
import Util from '../Util';

import Colors from '../styles/Colors';

const Wrapper = styled.div`
  position: relative;
  z-index: -1;
`;

const Diagonal = styled.div`
  height: ${() => (Util.isMobile() ? '40px' : '80px')};
  display: flex;
  width: 100%;
  transform: skew(0deg, -8deg);
  opacity: 1;
  z-index: -1;
`;

const Bar = styled.div`
  background: linear-gradient(
    to ${(props) => (props.reverse ? 'left' : 'right')},
    ${({ color }) => color},
    ${({ color }) => (color === 'transparent' ? color : 'transparent')}
  );
  height: 100%;
  width: ${(props) => props.width}%;
  z-index: -1;
`;

const rand = (from, to) => {
  return Math.floor(Math.random() * (to - from)) + from;
};

const widths = () => {
  const from = 25;
  const to = 40;
  const w1 = rand(from, to);

  let w3 = rand(from, to);
  while (w3 === w1) {
    w3 = rand(from, to);
  }

  return { w1, w2: 100 - w1 - w3, w3 };
};

const Separator = ({ single, style, from = Colors.primary, to = Colors.secondary, title }) => {
  let items = [widths(), widths(), widths()];

  if (single) {
    items = items.slice(0, 1);
  }

  return (
    <Wrapper style={{ marginTop: title ? 120 : 0, ...style }}>
      {title && (
        <LabelText
          className={`text-${Util.isMobile() ? 4 : 5}xl text-gray-600 text-center w-full px-4`}
          style={{
            position: 'absolute',
            top: '50%',
            textAlign: 'center',
            marginTop: Util.isMobile() ? -30 : -40,
            zIndex: 1,
          }}
        >
          {title}
        </LabelText>
      )}
      {items.map((w, i) => (
        <Diagonal key={i}>
          <Bar color={i % 2 ? from : to} width={w.w1} />
          <Bar color="transparent" width={w.w2} />
          <Bar color={i % 2 ? to : from} width={w.w3} reverse />
        </Diagonal>
      ))}
    </Wrapper>
  );
};

export default Separator;
