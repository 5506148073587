import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button from './Button';
import Colors from '../styles/Colors';
import Util from '../Util';
import Contact from './Contact';

const Background = styled.div`
  border-top: 15px solid ${Colors.secondary};
  background: #0b2540;
  width: 300%;
  position: absolute;
  height: 80%;
  transform: rotate(-8deg);
  transform-origin: 0 0;
  left: -10px;
  top: -20px;
  z-index: 0;
`;

const Wrapper = styled.div`
  position: relative;
  margin-top: ${() => (Util.isMobile() ? '100px' : '300px')};
`;

const Inner = styled.div`
  background: #0b2540;
  color: white;
  text-align: center;
  padding: 20px 0px 100px 0px;
  z-index: 10;
  position: relative;
`;

const Cta = ({ title, description, buttonLabel, buttonLink }) => {
  const { t } = useTranslation();
  const isMobile = Util.isMobile();

  return (
    <Wrapper>
      <Background />
      <Inner>
        <h3 className={`text-${isMobile ? 3 : 6}xl font-semibold z-10`}>{title || t('¿Mas información?')}</h3>
        <p className="mt-8 text-xl font-light">{description}</p>
        <p className="mt-8">
          <Contact linkTo={buttonLink}>
            <Button size="xl">{buttonLabel || t('Contactanos')}</Button>
          </Contact>
        </p>
      </Inner>
    </Wrapper>
  );
};

export default Cta;
