import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import marker from '@iconify/icons-mdi/map-marker';
import whatsapp from '@iconify/icons-mdi/whatsapp';
import phone from '@iconify/icons-mdi/phone';
import mail from '@iconify/icons-mdi/mail';
import Contact from '../Contact';
import Util from '../../Util';

const Footer = () => {
  const { t } = useTranslation();
  const isMobile = Util.isMobile();

  return (
    <div className="bg-primary z-10">
      <footer className="container mx-auto py-4 px-3 text-gray-100">
        <div className={`${isMobile ? '' : 'flex'} -mx-3`}>
          <div className="flex-1 p-10">
            <h2 className="text-lg font-semibold">Calyx</h2>
            <p className="mt-5">
              {t('Una prestigiosa consultora de tecnología, con sólida experiencia y el mejor equipo de expertos.')}
            </p>
          </div>
          <div className="flex-1 p-10">
            <h2 className="text-lg">{t('Servicios')}</h2>
            <ul style={{ marginLeft: 20 }}>
              <li className="mt-2">
                <Link to="/digital" className="text-gray-100">
                  {t('Transformación Digital')}
                </Link>
              </li>
              <li className="mt-2">
                <Link to="/erp" className="text-gray-100">
                  {t('Plataforma Integral ERP')}
                </Link>
              </li>
              <li className="mt-2">
                <Link to="/software" className="text-gray-100">
                  {t('Software Factory')}
                </Link>
              </li>
              <li className="mt-2">
                <Link to="/loan" className="text-gray-100">
                  {t('Staff Augmentation')}
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex-1 p-10">
            <h2 className="text-lg mb-3">
              <Link to="/company" className="text-gray-100">
                {t('Nosotros')}
              </Link>
            </h2>
            <h2 className="text-lg mb-3">
              <Contact />
            </h2>
            <h2 className="text-lg mb-3">
              <a href="https://www.linkedin.com/company/calyx-servicios/" className="text-gray-100">
                LinkedIn
              </a>
            </h2>
            <h2 className="text-lg mb-3">
              <a href="https://www.linkedin.com/company/calyx-servicios/jobs/" className="text-gray-100">
                {t('Trabajá con Nosotros')}
              </a>
            </h2>
            <h2 className="flex mt-10">
              <Icon icon={marker} style={{ marginTop: 3, marginRight: 5 }} />
              Av. Caseros 3515 1°B, Parque Patricios (Distrito Tecnológico), C.A.B.A
            </h2>
            <h2 className="flex mt-3">
              <Icon icon={phone} style={{ marginTop: 3, marginRight: 5 }} />
              +54-(11)-3986-0443
            </h2>
            <h2 className="flex mt-3">
              <Icon icon={whatsapp} style={{ marginTop: 3, marginRight: 5 }} />
              +54-(911)-6862-0901
            </h2>
            <h2 className="flex mt-3">
              <Icon icon={mail} style={{ marginTop: 3, marginRight: 5 }} />
              info@calyxservicios.com.ar
            </h2>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
