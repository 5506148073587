import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'simple-react-modal';
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import linkedinIcon from '@iconify/icons-mdi/linkedin';

import Util from '../Util';
import Card from './Card';
import Button from './Button';

const Avatar = styled.img`
  position: absolute;
  border-radius: 50%;

  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  height: ${(p) => (p.isMobile ? '100px' : '170px')};
  width: ${(p) => (p.isMobile ? '100px' : '170px')};
  left: ${(p) => (p.isMobile ? 'auto' : '-45px')};
  right: ${(p) => (p.isMobile ? '-10px' : 'auto')};
  top: ${(p) => (p.isMobile ? '-10px' : '-25px')};
`;

const Person = ({ name, image, title, summary, description, linkedin }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const isMobile = Util.isMobile();

  return (
    <>
      <Card className="mb-8 flex-1 flex flex-col" style={{ margin: 20, position: 'relative' }}>
        <div className="items-center flex flex-1">
          <Avatar src={image} alt={name} isMobile={isMobile} />
          <div style={{ paddingLeft: isMobile ? 0 : 130 }}>
            <p className="text-3xl font-semibold">{name}</p>
            <p className="text-sm text-gray-600">{title}</p>
            <p className="mt-4 flex-1">{summary}</p>
          </div>
        </div>
        <br />
        <Button onClick={() => setOpen(true)}>{t('Más')}</Button>
      </Card>
      <Modal show={open} onClose={() => setOpen(false)}>
        <div className="flex items-center mt-8 flex-1">
          <img className="w-12 h-12 mr-4" src={image} alt={name} />
          <div>
            <p className="text-xl font-semibold">{name}</p>
            <p className="text-sm text-gray-600">{title}</p>
          </div>
        </div>
        <p className="mt-6">{summary}</p>
        <br />
        {description}
        <div style={{ fontSize: 40, paddingTop: 10 }}>
          <a href={linkedin} target="_blank" rel="noopener noreferrer">
            <Icon icon={linkedinIcon} />
          </a>
        </div>
      </Modal>
    </>
  );
};

export default Person;
