import React, { useEffect } from 'react';
import chroma from 'chroma-js';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 60vh;
  width: 100%;
  transform: skew(0deg, -8deg);
  top: -20vh;
  position: absolute;
  overflow: hidden;
  opacity: 0.5;
  box-shadow: inset 0px 0px 40px 40px #dba632;
`;

const Canvas = styled.canvas`
  height: 100%;
  width: 100%;
`;

const R = (x, y, time) =>
  Math.floor(192 + 64 * Math.cos((x * x - y * y) / 300 + time));

const G = (x, y, time) =>
  Math.floor(
    192 +
      64 *
        Math.sin(
          (x * x * Math.cos(time / 4) + y * y * Math.sin(time / 3)) / 300
        )
  );

const B = (x, y, time) =>
  Math.floor(
    192 +
      64 *
        Math.sin(
          5 * Math.sin(time / 9) +
            ((x - 100) * (x - 100) + (y - 100) * (y - 100)) / 1100
        )
  );

const TopDiagonal = ({ colors }) => {
  useEffect(() => {
    const canvas = document.getElementById('canvas');
    const context = canvas.getContext('2d');
    let time = 0;

    const color = (x, y, r, g, b) => {
      context.fillStyle = chroma.mix(
        colors.from,
        `rgb(${r}, ${g}, ${b})`,
        0.25
      );
      context.fillRect(x, y, 10, 10);
    };

    const startAnimation = function () {
      for (let x = 0; x <= 30; x++) {
        for (let y = 0; y <= 30; y++) {
          color(x, y, R(x, y, time), G(x, y, time), B(x, y, time));
        }
      }
      time = time + 0.06;

      requestAnimationFrame(startAnimation);
    };

    startAnimation();
  }, [colors.from]);

  return (
    <Wrapper>
      <Canvas id="canvas" width="32" height="32" />
    </Wrapper>
  );
};

export default TopDiagonal;
