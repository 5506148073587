import React from 'react';
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import chroma from 'chroma-js';

const Title = styled.div`
  color: #222;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  width: 100%;
  margin-top: 10px;
`;

const Wrapper = styled.div`
  margin: 0px 10px 10px 10px;
  width: calc(100% / 3 - 20px);
  flex: 1;
`;

const IconWrapper = styled.div`
  background: linear-gradient(
    45deg,
    ${(props) => chroma(props.color).darken(1)},
    ${(props) => chroma(props.color).brighten(1)}
  );
  box-shadow: 12px 12px 32px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(225, 225, 225, 0.3);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 10px;
  height: 100px;
  width: 100%;
`;

const AppIcon = ({ icon, color, title }) => {
  return (
    <Wrapper>
      <IconWrapper color={color}>
        <Icon icon={icon} color="white" height="60" style={{ margin: 'auto' }} />
      </IconWrapper>
      <Title>{title}</Title>
    </Wrapper>
  );
};

export default AppIcon;
