import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';

const CarouselWrapper = ({ single, double, children }) => {
  return (
    <Carousel
      autoPlay
      showDots
      infinite
      responsive={{
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: single ? 1 : double ? 2 : 4,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: single ? 1 : double ? 2 : 3,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: single ? 1 : double ? 2 : 2,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: single ? 1 : double ? 2 : 1,
        },
      }}
      itemClass="p-5 z-10 mb-10"
    >
      {children}
    </Carousel>
  );
};

export default CarouselWrapper;
