import React from 'react';
import chroma from 'chroma-js';
import { useTranslation, Trans } from 'react-i18next';

import Colors from '../styles/Colors';
import { useViewportAnimations } from '../hooks/hooks';
import Cta from '../components/Cta';
import Layout from '../components/layout/Layout';
import Service from '../components/Service';
import Circles from '../components/Circles';
import Carousel from '../components/Carousel';
import Separator from '../components/Separator';
import ItemCard from '../components/ItemCard';
import Deck from '../components/Deck';
import TextBlock from '../components/TextBlock';
import Util from '../Util';

import infra from '../images/infra.png';
import browser from '../images/services/browser.png';
import android from '../images/services/android.png';
import ios from '../images/services/ios.png';
import logo from '../images/services/logo.png';

const colors = {
  from: Colors.four,
  to: chroma(Colors.four).brighten(1),
};

const logos = [
  {
    title: 'Web',
    logos: ['react.png', 'angular.png', 'html.png', 'css3.png', 'less.png'],
  },
  {
    title: 'Mobile',
    logos: ['ios.png', 'android.png', 'flutter.png', 'ionic.png', 'xamarin.png'],
  },
  {
    title: 'Database',
    logos: ['mysql.png', 'mongo.png', 'postgres.png', 'sql-server.png', 'oracle.png', 'azure-db.png'],
  },
  {
    title: 'Lenguajes',
    logos: ['python.png', 'java.png', 'ms.png', 'node.png', 'php.png'],
  },
  {
    title: 'Servers / API',
    logos: ['django.png', 'express.png', 'laravel.png', 'spring.png'],
  },
  {
    title: 'CMS',
    logos: ['wp.png', 'drupal.png', 'joomla.png', 'sp.png'],
  },
  {
    title: 'DevOps',
    logos: ['ansible.png', 'docker.png', 'jenkins.png', 'kubernetes.png', 'serverless.png'],
  },
  {
    title: 'Analytics',
    logos: ['talend.png', 'power-bi.png', 'tableau.png'],
  },
  {
    title: 'Cloud',
    logos: ['aws.png', 'azure.png', 'gc.png'],
  },
];

const tiles = [
  {
    color: Colors.pink0,
    src: logo,
    style: {
      backgroundPosition: '150% 150%',
      backgroundSize: '130%',
    },
  },
  {
    color: Colors.violet0,
    src: browser,
  },
  {
    color: Colors.red1,
    src: android,
  },
  {
    color: Colors.indigo1,
    src: ios,
  },
];

const Software = () => {
  useViewportAnimations();
  const { t } = useTranslation();

  return (
    <Layout>
      <Service
        colors={colors}
        tiles={tiles}
        title="Software Factory"
        sub={t('Tu socio estratégico')}
        description={
          <Trans>
            <p>
              {t(
                'Nos especializamos en el desarrollo integral de <strong>soluciones tecnológicas personalizadas</strong> y adaptadas a las necesidades del negocio.'
              )}
            </p>
            <br />
            <p>{t('Nuestra identidad digital innata impulsa la transformación en todas las industrias.')}</p>
            <br />
            <p>
              {t(
                'Siempre estamos listos para potenciar un cambio significativo con una visión estratégica hacia el futuro.'
              )}
            </p>
          </Trans>
        }
      />
      <Separator title={t('Desarrollo Integral')} {...colors} />
      <TextBlock>
        <div className="lg:flex">
          <div className="flex-1">
            <p>{t('Trabajamos con nuestros clientes en la implementación completa de la solución óptima.')}</p>
            <br />
            <p>
              {t(
                'Desde la definición de los requerimientos, al diseño de bases de datos y API, hasta aplicaciones e interfaces mobile, web y IoT.'
              )}
            </p>
            <br />
            <p>
              {t(
                'Todos nuestros desarrollos incluyen testeo de calidad, DevOps y deployments en los principales clouds.'
              )}
            </p>
          </div>
          <img
            className="vp animate__delay-1s px-4 flex-1"
            style={{ width: Util.isMobile() ? '100%' : '20%' }}
            src={infra}
            alt="Desarrollo Integral"
          />
        </div>
      </TextBlock>
      <Separator title={t('Nuestras Virtudes')} {...colors} />
      <Deck i18nKey="softwareItems" />
      <Separator title={t('Tecnologías')} {...colors} />
      <Carousel>
        {logos.map((c, i) => (
          <ItemCard
            key={i}
            title={t(c.title)}
            style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
            content={
              <div style={{ marginTop: 40 }}>
                <Circles logos={c.logos} />
              </div>
            }
          />
        ))}
      </Carousel>
      <Cta />
    </Layout>
  );
};

export default Software;
