import React from 'react';
import Popover from 'react-simple-popover';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import menu from '@iconify/icons-mdi/menu';
import { Icon } from '@iconify/react';

import logo from '../../images/icon.png';
import Button from '../Button';
import Colors from '../../styles/Colors';

import Util from '../../Util';
import Contact from '../Contact';

const ListItem = ({ title, color, route }) => {
  return (
    <div>
      <Link to={`/${route}`} style={{ display: 'flex', padding: '5px 10px' }}>
        <div className="header-icon" style={{ background: color }} /> {title}
      </Link>
    </div>
  );
};

const LanguageChange = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const currentLanguage = i18n.language || (typeof window !== 'undefined' && window.localStorage.i18nextLng) || 'es';
  const isSpanish = currentLanguage === 'es';

  return (
    <div
      style={{
        cursor: 'pointer',
        alignItems: 'center',
        display: 'flex',
      }}
      onClick={() => changeLanguage(isSpanish ? 'en' : 'es')}
    >
      <a>{isSpanish ? 'English' : 'Español'}</a>
    </div>
  );
};

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popover: null,
    };
    this.services = React.createRef();
  }

  render() {
    const { t } = this.props;
    const isMobile = Util.isMobile();

    // Mobile header
    if (isMobile) {
      return (
        <header className="sticky top-0 bg-white shadow-lg" style={{ zIndex: 100 }}>
          <div className="container flex sm:flex-row justify-between mx-auto py-2 px-4">
            <Link to="/" className="flex-1">
              <div className="flex items-center text-xl">
                <div className="w-8">
                  <img height="24" width="24" src={logo} alt="logo" />
                </div>
                <div className="text-primary">Calyx</div>
              </div>
            </Link>

            <button
              ref={this.services}
              onClick={() => {
                this.setState({
                  popover: 'services',
                });
              }}
            >
              <Icon icon={menu} />
            </button>
            <Popover
              target={this.services.current}
              placement="bottom"
              container={this}
              style={{ width: '100%', left: 0 }}
              show={this.state.popover === 'services'}
              onHide={() =>
                this.setState({
                  popover: null,
                })
              }
            >
              <div className="">
                <h3>
                  <b>Services</b>
                </h3>
                <ListItem route="digital" title={t('Transformación Digital')} color={Colors.secondary} />
                <ListItem route="erp" title={t('Plataforma Integral ERP')} color={Colors.three} />
                <ListItem route="software" title="Software Factory" color={Colors.four} />
                <ListItem route="loan" title="Staff Augmentation" color={Colors.five} />
                <br />
                <h2 className="mb-3">
                  <Link to="/company">{t('Nosotros')}</Link>
                </h2>
                <h2 className="mb-3">
                  <Contact />
                </h2>
                <h2 className="mb-3">
                  <a href="https://www.linkedin.com/company/calyx-servicios/">LinkedIn</a>
                </h2>
                <h2 className="mb-3">
                  <a href="https://www.linkedin.com/company/calyx-servicios/jobs/">{t('Trabajá con Nosotros')}</a>
                </h2>
                <LanguageChange />
              </div>
            </Popover>
          </div>
        </header>
      );
    }

    return (
      <header className="sticky top-0 bg-white shadow-lg" style={{ zIndex: 100 }}>
        <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
          <Link to="/">
            <div className="flex items-center text-2xl">
              <div className="w-12 mr-3">
                <img height="48" width="48" src={logo} alt="logo" />
              </div>
              <div className="text-primary">Calyx</div>
            </div>
          </Link>
          <div className="flex mt-4 sm:mt-0">
            <div
              style={{
                cursor: 'pointer',
                alignItems: 'center',
                display: 'flex',
              }}
              className="px-4"
              ref={this.services}
              onClick={() =>
                this.setState({
                  popover: 'services',
                })
              }
              onMouseEnter={() => {
                this.setState({
                  popover: 'services',
                });
              }}
              onMouseLeave={() => {
                this.setState({
                  popover: null,
                });
              }}
            >
              <a>{t('Servicios')}</a>
              <Popover
                target={this.services.current}
                placement="bottom"
                container={this}
                style={{ width: 250 }}
                show={this.state.popover === 'services'}
                onHide={() =>
                  this.setState({
                    popover: null,
                  })
                }
              >
                <ListItem route="digital" title={t('Transformación Digital')} color={Colors.secondary} />
                <ListItem route="erp" title={t('Plataforma Integral ERP')} color={Colors.three} />
                <ListItem route="software" title="Software Factory" color={Colors.four} />
                <ListItem route="loan" title="Staff Augmentation" color={Colors.five} />
              </Popover>
            </div>
            <div
              style={{
                cursor: 'pointer',
                alignItems: 'center',
                display: 'flex',
              }}
              className="px-4"
            >
              <Link to="/company">{t('Nosotros')}</Link>
            </div>
            <div
              style={{
                cursor: 'pointer',
                alignItems: 'center',
                display: 'flex',
              }}
              className="px-4"
            >
              <a
                href="https://www.linkedin.com/company/calyx-servicios/jobs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('Trabajá con Nosotros')}
              </a>
            </div>
            <div style={{ cursor: 'pointer' }} className="px-4 flex mr-3">
              <Contact button />
            </div>
            <LanguageChange />
          </div>
        </div>
      </header>
    );
  }
}

const Pre = (props) => {
  const { t } = useTranslation();
  return <Header t={t} {...props} />;
};

export default Pre;
