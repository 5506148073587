import React from 'react';
import './css/main.css';
import './css/index.css';
import './i18n';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './pages/index';
import Company from './pages/company';
import Digital from './pages/digital';
import Erp from './pages/erp';
import Loan from './pages/loan';
import Software from './pages/software';
import ScrollToTop from './ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route exact name="home" path="/">
            <Home />
          </Route>
          <Route name="digital" path="/digital">
            <Digital />
          </Route>
          <Route name="erp" path="/erp">
            <Erp />
          </Route>
          <Route name="loan" path="/loan">
            <Loan />
          </Route>
          <Route name="company" path="/company">
            <Company />
          </Route>
          <Route name="software" path="/software">
            <Software />
          </Route>
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
