import React, { useState } from 'react';
import Modal from 'simple-react-modal';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import atomIcon from '@iconify/icons-mdi/atom-variant';
import rocketIcon from '@iconify/icons-mdi/rocket-launch';
import codeIcon from '@iconify/icons-mdi/code-braces';
import humanIcon from '@iconify/icons-mdi/human-greeting';

import { useViewportAnimations } from '../hooks/hooks';
import Button from '../components/Button';
import Carousel from '../components/Carousel';
import Sphere from '../components/Sphere';
import Cta from '../components/Cta';
import ItemCard from '../components/ItemCard';
import Deck from '../components/Deck';
import Separator from '../components/Separator';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import Map from '../components/Map';
import Bubbles from '../components/Bubbles';

import Util from '../Util';
import TextBlock from '../components/TextBlock';

const Fade = styled.div`
  display: block;
  position: relative;

  &:before {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px -80px 40px 0px white;
  }
`;

const customers = [
  'unilever.png',
  'teaconnection.png',
  'skf.png',
  'sanjuan.png',
  'sabores.png',
  'redlink.png',
  'panamerican.png',
  'maynar.png',
  'mcr.png',
  'greeneat.png',
  'etil.png',
  'edesur.png',
  'darc.png',
  'cepas.png',
  'bacardi.png',
  'avon.png',
  'amcor.png',
  'assa.png',
  'cashcollector.png',
  'neomix.png',
  'iting.png',
];

const Header = ({ children, ...rest }) => (
  <h3
    {...rest}
    className={`leading-tight uppercase ${Util.isMobile() ? 'mt-24' : 'mt-40'} text-${
      Util.isMobile() ? 4 : 5
    }xl text-gray-600 text-center w-full`}
  >
    {children}
  </h3>
);

const items = (t) => [
  {
    icon: rocketIcon,
    title: t('Transformación Digital'),
    content: `<p>
        <br />
        ${t(
          'Lideramos la digitalización y automatización de procesos para optimizar tiempos, reducir riesgos y mejorar la productividad'
        )}
      </p>`,
    linkTo: 'digital',
    color: 'bg-secondary',
  },
  {
    icon: atomIcon,
    title: t('Plataforma Integral ERP'),
    content: `<p><br/>${t(
      'Implementación e integración de tecnología ERP para simplificar los procesos y tareas de su Compañía'
    )}</p>`,
    linkTo: 'erp',
    color: 'bg-three',
  },
  {
    icon: codeIcon,
    title: t('Software Factory'),
    content: t(
      `<p><br/>${t(
        'Desarrollo de soluciones de software y app mobile a medida, trabajando con equipos especializados en metodologías Agile/Scrum para tener resultados eficientes'
      )}</p>`
    ),
    linkTo: 'software',
    color: 'bg-four',
  },
  {
    icon: humanIcon,
    title: t('Staff Augmentation'),
    content: `<p><br/>${t('Outsourcing de nuestro staff calificado para integración inmediata con equipos de IT')}</p>`,
    linkTo: 'loan',
    color: 'bg-five',
  },
];

export default () => {
  const [article, setArticle] = useState(null);
  const { t } = useTranslation();
  useViewportAnimations();

  const isMobile = Util.isMobile();

  return (
    <Layout>
      <Sphere />
      <section className="pt-20 md:pt-40">
        <div className="container flex flex-col">
          <div style={{ height: 200, margin: 'auto', padding: isMobile ? '0px 40px' : '0px 250px' }}>
            <h1 className="text-primary animate__animated animate__fadeInUp" style={{ fontSize: isMobile ? 80 : 120 }}>
              Calyx
            </h1>
            <br />
            <h1 className="text-4xl lg:text-5xl xl:text-5xl font-bold leading-none animate__animated animate__fadeInUp animate__delay-1s">
              {t('Transformando el futuro hoy')}
            </h1>
            <p className="text-xl lg:text-3xl mt-6 font-light animate__animated animate__fadeInUp animate__delay-2s">
              {t('Una prestigiosa consultora de tecnología, con sólida experiencia y el mejor equipo de expertos.')}
            </p>
          </div>
        </div>
      </section>
      <Separator />
      <Header>{t('Sabemos lo que hacemos')}</Header>
      <SplitSection
        alt
        primarySlot={
          <TextBlock style={{ padding: '0px 50px' }}>
            <Trans i18nKey="weKnow" components={{ p: <p style={{ marginBottom: 15 }} /> }} />
          </TextBlock>
        }
        secondarySlot={
          <Fade className="vp animate__delay-1s hidden">
            <img
              className="xl:px-0 px-10"
              alt="robots"
              src="https://i2.wp.com/www.raadsys.com/wp-content/uploads/2020/03/robot2.png?fit=600%2C513&ssl=1"
            />
          </Fade>
        }
      />
      <div className="container mx-auto text-center">
        <Header>{t('Nuestros Servicios')}</Header>
        <Deck items={items(t)} />
      </div>
      <section id="customers" style={{ position: 'relative' }}>
        <Header style={{ zIndex: 10, position: 'relative' }}>
          <br />
          {t('Clientes')}
        </Header>
        <div
          style={{
            position: 'absolute',
            height: 'calc(100% + 150px)',
            width: '100%',
            backgroundColor: '#E6EEF7',
            transform: 'skew(0deg, -8deg)',
            zIndex: 5,
            top: 0,
          }}
        ></div>
        <Bubbles logos={customers.map((c) => require(`../images/customers/${c}`))} />
      </section>
      <section id="casos" style={{ marginTop: 240 }}>
        <Header>{t('Casos de Éxito')}</Header>
        <div className="container mx-auto mt-10 z-10">
          <Carousel>
            {t('caseStudies', { returnObjects: true }).map((c, i) => (
              <ItemCard
                key={i}
                title={
                  <div className="text-center" style={{ minHeight: 100 }}>
                    {c.title}
                  </div>
                }
                description={`${c.description.slice(0, 175)}...`}
                metric={c.roi}
                onClick={() => setArticle(c)}
              />
            ))}
          </Carousel>
          {article && (
            <Modal
              show={!!article}
              onClose={() => setArticle(null)}
              containerStyle={{ width: isMobile ? '90%' : '60%' }}
            >
              <div className="flex mt-8 flex-1">
                <p className="text-xl font-semibold">{article.title}</p>
              </div>
              <br />
              <>
                <p>{article.description}</p>
                <br />
                <p>
                  <b>ROI: </b>
                  {article.roi}
                </p>
              </>
              <br />
              <br />
            </Modal>
          )}
        </div>
      </section>
      <section
        style={{
          position: 'relative',
          paddingTop: 40,
          marginTop: 100,
          marginBottom: Util.isMobile() ? 0 : 200,
          paddingBottom: 48,
        }}
      >
        <Header style={{ position: 'relative' }} className="z-10">
          {t('Por qué elegirnos')}
        </Header>
        <TextBlock style={{ zIndex: 10, position: 'relative' }}>
          <Trans i18nKey="whyUs" components={{ p: <p style={{ marginBottom: 15 }} /> }} />
        </TextBlock>
        <Map noLabels />
      </section>
      <Separator title={t('Nosotros')} />
      <SplitSection
        id="about"
        reverseOrder
        primarySlot={
          <TextBlock>
            <p>
              {t(
                'En Calyx Servicios conocemos el mercado de IT y estamos comprometidos en impulsar continuamente la innovación en software y en facilitar la experiencia de nuestros clientes mediante soluciones de vanguardia.'
              )}
            </p>
            <br />
            <Link to="company">
              <Button className="bg-five">{t('Más')}</Button>
            </Link>
          </TextBlock>
        }
        secondarySlot={
          <Fade className="vp animate__delay-1s hidden">
            <img src="https://www.robotturn.com/fileadmin/_processed_/e/4/csm_robotturn_handshake_picture-bg-big_5d04d3e7c3.png" />
          </Fade>
        }
      />
      <Cta />
    </Layout>
  );
};
