import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import chroma from 'chroma-js';
import Colors from '../styles/Colors';

import { useViewportAnimations } from '../hooks/hooks';
import Layout from '../components/layout/Layout';
import Service from '../components/Service';
import Separator from '../components/Separator';
import Cta from '../components/Cta';
import Deck from '../components/Deck';
import TextBlock from '../components/TextBlock';

const colors = {
  from: Colors.five,
  to: chroma(Colors.five).brighten(1),
};

const tiles = [
  {
    color: Colors.indigo1,
    src:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  },
  {
    color: Colors.indigo1,
    src:
      'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  },
  {
    color: Colors.violet0,
    src:
      'https://images.unsplash.com/photo-1504257432389-52343af06ae3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  },
  {
    color: Colors.blue0,
    src:
      'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  },
];

const Software = () => {
  useViewportAnimations();
  const { t } = useTranslation();

  return (
    <Layout>
      <Service
        tiles={tiles}
        colors={colors}
        title="Staff Augmentation"
        sub={t('Aportamos talento a tus equipos de IT para potenciar tus proyectos')}
        color={Colors.five}
        description={
          <>
            <p>
              {t(
                'Sabemos que incorporar personal de IT a su organización es un factor clave, y contar con recursos técnicos expertos se ha convertido en un objetivo cada vez más complejo.'
              )}
            </p>
            <br />
            <p>
              {t(
                'Para ello, brindamos todo el soporte y know how ofreciendo flexibilidad y capacidad de adaptación rápida con procesos de desarrollo más efectivos y en menos tiempo, a través de profesionales adecuados para tus proyectos y especializados en las últimas tecnologías.'
              )}
            </p>
          </>
        }
      />
      <Separator title={t('¿Cómo reclutamos?')} {...colors} />
      <TextBlock>
        <Trans>
          <p>
            {t(
              'Nuestro equipo de Recruiting a través de una metodología 100% probada, garantiza que los recursos cuenten con las <strong>validaciones técnicas y habilidades</strong> blandas necesarias para el desenvolvimiento en equipos de IT de forma inmediata. Testeamos rigurosamente las aptitudes de razonamiento lógico / matemático, la capacidad técnica y las competencias personales en nuestro proceso de entrevistas y sólo se presentan a nuestros clientes aquellos profesionales con <strong>mejores puntuaciones</strong>.'
            )}
          </p>
          <br />
          <p>
            {t(
              'Contamos con los mejores bancos de datos para ofrecer los <strong>perfiles más sofisticados</strong> en un mercado dinámico con un equipo de Recruiting conectado 24/7.'
            )}
          </p>
        </Trans>
      </TextBlock>
      <Separator title={t('Ventajas')} {...colors} />
      <Deck i18nKey="loanItems" />
      <Cta />
    </Layout>
  );
};

export default Software;
