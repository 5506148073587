import React from 'react';

const StatsBox = ({ primaryText, secondaryText }) => (
  <>
    <p className="pt-2 text-3xl lg:text-3xl font-semibold text-primary">
      {primaryText}
    </p>
    <p className="font-semibold mb-6">{secondaryText}</p>
  </>
);

export default StatsBox;
