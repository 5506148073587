import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Cache from 'i18next-localstorage-cache';

import taxes from '@iconify/icons-mdi/file-document-edit-outline';
import human from '@iconify/icons-mdi/human-queue';
import dev from '@iconify/icons-mdi/developer-board';
import chart from '@iconify/icons-mdi/chart-areaspline';
import inventory from '@iconify/icons-mdi/file-table-box-multiple';
import currency from '@iconify/icons-mdi/home-currency-usd';
import edit from '@iconify/icons-mdi/account-edit';
import lock from '@iconify/icons-mdi/account-lock';
import puzzle from '@iconify/icons-mdi/puzzle';
import speedometer from '@iconify/icons-mdi/speedometer';
import tv from '@iconify/icons-mdi/television-guide';
import webhookIcon from '@iconify/icons-mdi/webhook';
import humanGreetingProximity from '@iconify/icons-mdi/human-greeting-proximity';
import humanCapacityIncrease from '@iconify/icons-mdi/human-capacity-increase';
import bullseyeArrow from '@iconify/icons-mdi/bullseye-arrow';
import quality from '@iconify/icons-mdi/quality-high';
import flex from '@iconify/icons-mdi/arrow-expand-horizontal';
import clock from '@iconify/icons-mdi/clock-time-eight-outline';
import horizontal from '@iconify/icons-mdi/arrow-left-right';
import vertical from '@iconify/icons-mdi/arrow-up-down';

const resources = {
  es: {
    translation: {
      weKnow: `
        <p>Traducimos las <strong>estrategias</strong> de negocios a objetivos de <strong>transformación digital</strong> concretos.</p>
        <p>Hacemos análisis de procesos para detectar oportunidades de <strong>digitalización y automatización</strong>.</p>
        <p>Definimos los programas y proyectos como Partners de negocios.</p>
        <p>Aportamos metodología <strong>Agile</strong> de trabajo y tecnologías de vanguardia.</p>
        <p>Estimamos los beneficios económicos, organizacionales y culturales del cambio.</p>
        <p>Definimos los requisitos, <strong>implementamos y lideramos</strong> la transformación digital.</p>
        <p>Aportamos representaciones y partnerships para potenciar las ganancias económicas del <strong>cambio tecnológico</strong>.</p>
      `,
      whyUs: `
        <p>
          Ofrecemos servicios de calidad a través de personal altamente calificado, expertos en transformación digital y automatizaciones, con sólidas habilidades en diferentes idiomas, que nos permiten comunicarnos con cualquier parte del mundo, independientemente del huso horario. Somos una compañía que se destaca por la integración de sus colaboradores con sus equipos de IT de manera ágil y eficiente.
        </p>
        <p>
          Y tener claro cuáles son estas ventajas y aprovecharlas es vital para lograr resultados extraordinarios y que el proyecto resulte exitoso.
        </p>
        <p>
          El mundo globalizado de hoy permite ser más eficientes en el desarrollo de un proyecto de IT, abriendo la posibilidad de trabajar en forma remota y tercerizar en países que ofrezcan mejores ventajas competitivas tales como precio, capacidades técnicas, idioma, flexibilidad de horario laboral y capacidad para integrarse.
        </p>
        <p>
          Por eso, y mucho más, somos tu Partner de excelencia para todos tus proyectos de IT.
        </p>
      `,
      digitalAdvantages: `
        <ul>  
          <li>En el 100% de los casos de implementaciones de RPA nuestros clientes tuvieron un Retorno de la Inversión <strong>superior al 200%</strong>.</li>
          <li>El tiempo de recupero de la inversión no supera los <strong>6 a 8 meses</strong>.</li>
          <li>El ahorro de costo promedio de esta tecnología equivale al <strong>65% del costo laboral</strong> que se reemplaza.</li>
          <li>La productividad de los equipos de trabajo en nuestros clientes incrementó <strong>más de un 77%</strong>.</li>
          <li>Los riesgos de errores disminuyeron en promedio <strong>un 83%</strong>.</li>
          <li>En el <strong>100% de los casos</strong> los tiempos de cumplimiento de las tareas o procesos se han mejorado.</li>
          <li>Los robots nunca duermen, no cometen errores y son mucho menos costosos por hora hombre trabajada.</li>
        </ul>
      `,
      digitalCases: [
        {
          title: 'FINANZAS Y CONTABILIDAD',
          icon: chart,
          content: `<ul>
              <li>Reporting y consolidación</li>
              <li>Data entry de asientos contables</li>
              <li>Conciliaciones bancarias</li>
              <li>Conciliaciones de Cuentas contables</li>
              <li>ABM bases de datos</li>
              <li>Cash Flow</li>
              <li>Gestión de activos fijos</li>
              <li>Reportes en Power BI</li>
            </ul>`,
        },
        {
          title: 'LIQUIDACIÓN DE IMPUESTOS',
          icon: taxes,
          content: `<ul>
              <li>Generación de liquidaciones fiscales</li>
              <li>Presentación de las declaraciones juradas en el Organismo Recaudador</li>
              <li>Check list de cumplimiento de obligaciones ficales recurrentes</li>
              <li>Generación de novedades del domicilio electrónico</li>
              <li>Actualización de datos fiscales de bases de clientes y proveedores</li>
              <li>Control de bases de proveedores y documentos de base apócrifa</li>
            </ul>`,
        },
        {
          title: 'COMPRAS E INVENTARIOS',
          icon: inventory,
          content: `<ul>
              <li>Generación de órdenes de compra</li>
              <li>Data entry de facturas, remitos y pagos</li>
              <li>Análisis de licitaciones</li>
              <li>Planificación de compras e inventarios</li>
              <li>Gestión de devoluciones</li>
              <li>Importación de listas de precios</li>
              <li>Gestión de logística de inventarios</li>
              <li>Generación de pedidos </li>
            </ul>`,
        },
        {
          title: 'VENTAS Y COBRANZAS',
          icon: currency,
          content: `<ul>
              <li>Generación de oportunidades</li>
              <li>Generación de pedidos</li>
              <li>Generación de la facturación</li>
              <li>Importación de listas de precios</li>
              <li>Chat bots de atención al cliente</li>
              <li>ABM base de clientes</li>
              <li>Cálculo de comisiones de ventas</li>
              <li>Generación de rutas de entrega</li>
              <li>Reportes en Power BI</li>
              <li>Data Analytics para acciones de marketing</li>
            </ul>`,
        },
        {
          title: 'IT',
          icon: dev,
          content: `<ul>
              <li>Monitoreo de servidores</li>
              <li>Sincronización, eliminación y vaciado de carpetas</li>
              <li>Nivel 1 soporte con chatbots</li>
              <li>Procesos Batch</li>
              <li>FTP descarga, carga y soporte </li>
              <li>Testings</li>
            </ul>`,
        },
        {
          title: 'RECURSOS HUMANOS',
          icon: human,
          content: `<ul>
              <li>Gestión de novedades</li>
              <li>Proceso de recruiting</li>
              <li>Gestión de vacaciones y licencias</li>
              <li>Gestión de recibos de sueldos</li>
              <li>Liquidación de nómina</li>
              <li>Onboarding</li>
              <li>Help desk preguntas frecuentes</li>
            </ul>`,
        },
      ],
      erpAdvantages: [
        {
          icon: currency,
          title: 'Controla y Reduce Costos',
          content: `
          <ul>
            <li>Cero infraestructura</li>
            <li>Sin costos escondidos</li>
            <li>Pago por uso (usuarios/apps)</li>
          </ul>
        `,
        },
        {
          icon: puzzle,
          title: 'Características Completas e Integradas',
          content: `
          <ul>
            <li>Integración sencilla</li>
            <li>Una app para cada necesidad de negocio</li>
            <li>Escala tu software en cualquier momento</li>
          </ul>
        `,
        },
        {
          icon: tv,
          title: 'Interfaz Sencilla de Usar',
          content: `
          <ul>
            <li>Siempre accesible
            <li>Actualizaciones frecuentes</li>
            <li>Compatible en varios dispositivos</li>
          </ul>
        `,
        },
        {
          icon: speedometer,
          title: 'Implementación Rápida',
          content: `
          <ul>
            <li>Acceso instantáneo a nuevo software
            <li>Implementaciones cortas</li>
            <li>Adopción rápida por los empleados</li>
          </ul>
        `,
        },
        {
          icon: lock,
          title: 'Seguro',
          content: `
          <ul>
            <li>Odoo garantiza SLA: 99.9%
            <li>Actualizaciones de seguridad</li>
            <li>1 backup al día, 3 data centers</li>
          </ul>
        `,
        },
        {
          icon: edit,
          title: 'Personalizable',
          content: `
          <ul>
            <li>No se requiere conocer programación
            <li>Personaliza campos, vistas y procesos</li>
            <li>Flexibilidad técnica</li>
          </ul>
        `,
        },
      ],
      softwareItems: [
        {
          title: 'CALIDAD',
          icon: quality,
          content:
            '<p><br/>Personal de IT altamente calificado, creativo y con vasta experiencia en proyectos de alto impacto</p>',
        },
        {
          title: 'COMUNICACIÓN',
          icon: humanGreetingProximity,
          content: '<p><br/>Manejo fluido de idioma español e inglés para dirección proyectos internacionales</p>',
        },
        {
          title: 'COLABORACIÓN',
          icon: humanCapacityIncrease,
          content: '<p><br/>Sumamos trabajo de calidad a tus equipos de manera concreta y eficaz</p>',
        },
        {
          title: 'ENFOQUE',
          icon: bullseyeArrow,
          content: '<p><br/>Desarrolladores y Team Leaders orientados a objetivos y resultados</p>',
        },
        {
          title: 'METODOLOGÍA',
          icon: webhookIcon,
          content: '<p><br/>Equipo de Project Managers especializados en metodología Scrum/Agile</p>',
        },
        {
          title: 'FLEXIBILIDAD',
          icon: flex,
          content: '<p><br/>Equipo dinámico y adaptable a las necesidades del proyecto</p>',
        },
      ],
      loanItems: [
        {
          icon: clock,
          title: 'Ahorro de tiempo',
          content:
            '<p><br/>Cumpla con sus plazos y ponga a trabajar nuestro proceso de contratación las 24 horas del día para su equipo. Encuentre los profesionales adecuados para cada puesto en menos tiempo.</p>',
        },
        {
          icon: human,
          title: 'Aumento de capacidad',
          content:
            '<p><br/>Integre rápidamente profesionales altamente calificados que aumentan la capacidad de su equipo y agregan valor a su proyecto.</p>',
        },
        {
          icon: vertical,
          title: 'Conocimientos técnicos instantáneos',
          content:
            '<p><br/>Minimice el tiempo de inactividad en proyectos con la experiencia de un equipo extendido.</p>',
        },
        {
          icon: horizontal,
          title: 'Flexibilidad',
          content:
            '<p><br/>Aumente o disminuya el tamaño de su fuerza laboral de desarrollo según sea necesario con talento a pedido.</p>',
        },
      ],
      caseStudies: [
        {
          title: 'CONCILIACIONES BANCARIAS, PLATAFORMAS DE PAGO Y TARJETAS DE CRÉDITO',
          description:
            'La solución para el cliente implica el desarrollo de una solución de RPA para la conciliación de datos que se extraerán a  través de una serie de automatizaciones para poder obtener extractos bancarios desde distintos portales o recibidos vía mail, resúmenes de liquidaciones de tarjetas de crédito, movimientos de transacciones en plataformas de pago electrónicas, y validando la misma con la información del sistema ERP y los sistemas de puntos de ventas de la compañía. Luego se realiza una automatización adicional para registrar contablemente todo lo necesario y resultante del proceso de conciliación y generar reportes sobre el seguimiento de las diferencias y gestión de reclamos a las áreas intervinientes.',
          roi: '+400%',
        },
        {
          title: 'AUTOMATIZACIÓN DE LA GENERACIÓN DE ASIENTOS CONTABLES MANUALES EN ERP',
          description:
            'El bot desarrollado descarga automáticamente reportes del sistema de gestión de inventarios de la compañía, procesa los datos, los concilia con los stocks ingresados en el sistema ERP y, en base a los mismos, y genera los asientos manuales correspondientes en el módulo contable del ERP de la compañía.',
          roi: '+480%',
        },
        {
          title: 'AUTOMATIZACIÓN DE CUENTAS POR PAGAR',
          description:
            'El bot desarrollado descarga automáticamente facturas comerciales y remitos de entregas desde portales web o recibidos vía mail, obtiene los datos relevantes mediante extracción de datos por OCR, realiza reportes de seguimiento de recepción de facturas/remitos y valida cada una contra el remito para ser ingresada al sistema ERP. Luego los comprobantes validados son ingresados por el bot automáticamente en el ERP de la compañía detallando la cuenta contable, el centro de costos, las cantidades recibidas, el valor pactado y las condiciones de pago. El bot también genera un reporte de diferencias con las facturas no conciliadas y notificando por mail a los responsables de verificación de las mismas.',
          roi: '+700%',
        },
        {
          title: 'AUTOMATIZACIÓN DE LA GESTIÓN DE COBRANZAS',
          description:
            'Se desarrolló la automatización del proceso de gestión de cobranzas de deuda en la etapa pre-judicial. Este proceso (core del negocio) implicaba el envío de comunicados en tres etapas por tres medios distintos: email, SMS y llamada, todos realizados manualmente. El RPA automatizó todo el proceso, tomando la información del sistema de gestión generando las comunicaciones a través de todos los medios de comunicación indicados y sumando la tecnología de WhatsApp, dejando solo la intervención de las personas en caso de respuestas o en las etapas finales de la gestión de la cobranza.',
          roi: '+375%',
        },
        {
          title: 'AUTOMATIZACIÓN DE GENERACIÓN DE REPORTES',
          description:
            'Se implementó la automatización de generación de reportes mediante un bot que integra la información generada por el ERP consolidándola en el formato requerido por Casa Matriz para ser reportado a otro sistema de Reporting. El bot realiza la importación automática del reporte al sistema de la Casa Matriz una vez que es autorizado por la Compañía y envia un informe de presentación.',
          roi: '+450%',
        },
        {
          title: 'AUTOMATIZACIÓN DEL PROCESO DE RENDICIÓN DE GASTOS DE EMPLEADOS (T&E)',
          description:
            'Se automatizó todo el proceso de rendición de gastos de viáticos de los empleados. La solución permite que los distintos colaboradores que posee la empresa dentro y fuera del país (más de 700), puedan realizar el ingreso de sus gastos de una forma ágil, simple y sencilla. Cada rendición pasa por una serie de aprobaciones y revisiones automáticas que se informan a Cuentas por Pagar y Tesorería, quienes reciben las rendiciones ya aprobadas para emitir los pagos a cada empleado. La solución incluye la automatización de los registros contables en el ERP de los gastos rendidos.',
          roi: '+700%',
        },
        {
          title: 'AUTOMATIZACIÓN DE PROCESOS DE RRHH',
          description:
            'Se desarrolló una solución para el cliente con el fin de automatizar y sistematizar varios de los procesos de RRHH: análisis de ausentismo, solicitud de licencias, gestión del otorgamiento de vacaciones, generación y envío de recibos de sueldos con firma electrónica de los empleados, evaluaciones de desempeño, entrega y validación de objetivos.',
          roi: '+850%',
        },
      ],
      // Companys
      about: `
      <p>
        Somos parte del grupo <a>PGK Consultores</a>, una consultora integral con sólida experiencia en el mercado que hoy cuenta con más de 170 profesionales.
      </p>
      <p>
        Desde hace 15 años brindamos servicios de calidad a través de nuestro personal de IT calificado, creativo
        y con amplia experiencia. En Calyx encontrarás especialistas siempre a disposición para comenzar proyectos
        de manera inmediata y en un mercado donde la demanda supera la oferta.
      </p>
      <p>
        Entendemos las necesidades de nuestros clientes, somos flexibles y nos adaptamos a ellas, acompañándolos
        desde el comienzo de los proyectos y dando el asesoramiento oportuno para su correcta administración.
      </p>
      <p>
        Gracias al manejo fluido de idioma español e inglés, tenemos capacidad para desarrollar proyectos
        internacionales y brindamos servicios a clientes radicados en Argentina y en el exterior, respondiendo a
        los requerimientos que puedan surgir en todas las industrias y en todo tipo de empresa: multinacionales,
        grandes empresas nacionales y PyMEs.
      </p>
      <p>
        Contamos con más de 150 clientes en el mercado, en diferentes industrias.
      </p>
      `,
      team: `
        <p>
          Desde Tech Leads hasta ingenieros de QA y Project Managers, nuestros equipos están compuestos por expertos
          en todos los campos, capaces para satisfacer sus necesidades específicas. Podemos proporcionar todos los
          roles necesarios para hacer realidad su proyecto. Tenemos el conocimiento técnico para identificar
          rápidamente una hoja de ruta ganadora para su propósito, gestionando el mejor talento para agregar valor
          en cada paso del camino.
        </p>
        <p>
          Contamos con gerentes de proyecto dedicados que hacen un seguimiento minucioso de cada propuesta con
          especial atención a los KPIs, y procesos internos integrales implementados que garantizan que nuestros
          equipos siempre permanezcan comprometidos y productivos.
        </p>
        <p>
          Contamos con un equipo de expertos: Project Managers, Líderes Técnicos, Desarrolladores de
          automatizaciones, Arquitectos, Ingenieros de SF, DEVOPS, Desarrolladores Odoo y Analistas funcionales,
          entre otros.
        </p>
      `,
      politicaCalidad: `      
      <p>En Calyx, empresa dedicada al diseño, desarrollo e implementación de soluciones técnicas y de automatización, tenemos como meta comprender el negocio de nuestros clientes y así diseñar e implementar soluciones de automatización y transformación digital de alto valor agregado que contribuyan al logro de sus objetivos.</p>
      <p>Asumimos el compromiso de mejorar continuamente la eficacia de nuestro Sistema de Gestión de Calidad basado en la Norma Internacional ISO 9001 vigente y nos proponemos:</p>
      <p>Foco en el cliente como un socio de negocios, entendiendo sus necesidades y adaptándonos a ellas procurando la satisfacción de sus necesidades y expectativas.</p>
      <p>Fomentar la cultura de la gestión de riesgos, calidad y mejora de procesos en todo el personal de la Empresa buscando la mejora continua de nuestro SGC.</p>
      <p>Equipos de talento de alto rendimiento, asegurando un ambiente laboral que promueva la motivación, participación y el desarrollo profesional mediante la capacitación y utilización de herramientas de vanguardia.</p>
      <p>Cumplir con los requisitos legales y reglamentarios que nos aplican.</p>
      <p>Satisfacer los requisitos de nuestras partes interesadas.</p>
      `,
      reynaldoTitle: 'Líder de Consultoría en Tecnología',
      reynaldoSummary:
        'Reynaldo cuenta con más de 10 años de experiencia en software trabajando para clientes de diferentes industrias: Consumo Masivo, Retail, Utilities, Energia y Banca Privada. Se unió a Calyx como Partner desde sus comienzos, y desde entonces es responsable de las áreas de Transformación Digital & Software Factory, liderando proyectos de desarrollo y consultoría de alta complejidad, tanto para clientes de Argentina como de Colombia, España, Estados Unidos, entre otros. Su pasión por liderar nuevos desafíos tecnológicos y mejorar la experiencia de los usuarios son guía del equipo de Calyx.',
      reynaldoDescription: `
        <p>
          Es Ingeniero en Informática graduado de la Universidad Argentina de la Empresa en Buenos Aires. Obtuvo
          además importantes certificaciones como Scrum Foundation Professional Certificate y cuenta con amplia
          experiencia en el manejo de tecnologías de vanguardia como Python, Angular, MySQL y Microsoft SQL Server.
        </p>
        <br />
        <p>
          Actualmente es Líder de Consultoría en Tecnología en Calyx y tiene a su cargo importantes clientes de
          diversas industrias como Unilever, Banco Provincia, Banco Hipotecario, HSBC, Edesur, PAE Energy, Red Link,
          Green EAT, Tea Connection, Bacardi, DARC Peugeot/Citroen, Maynar Volkswagen, SKF, Grupo Cepas, AVON,
          Etilfarma, Talleres Sustentables, entre otros.
        </p>
      `,
    },
  },
  en: {
    translation: {
      // Home
      'Contacto': 'Contact',
      'Trabajá con Nosotros': 'Careers',
      'Servicios': 'Services',
      'Nosotros': 'About Us',
      'Clientes': 'Clients',
      'Casos de Éxito': 'Success Stories',
      'Transformando el futuro hoy': 'Transforming the future today',
      'Una prestigiosa consultora de tecnología, con sólida experiencia y el mejor equipo de expertos.':
        'A prestigious technology consulting company, with solid experience and the best experts team',
      'Sabemos lo que hacemos': "We know what we're doing",
      'Nuestros Servicios': 'Our Services',
      'Transformación Digital': 'Digital Transformation',
      'Lideramos la digitalización y automatización de procesos para optimizar tiempos, reducir riesgos y mejorar la productividad':
        'We lead the digitalization and automation of processes for improved efficiency, risk reduction and enhanced productivity',
      'Plataforma Integral ERP': 'ERP Platform',
      'Implementación e integración de tecnología ERP para simplificar los procesos y tareas de su Compañía':
        'ERP technology implementation and integration to simplify tasks and processes of your company',
      'Desarrollo de soluciones de software y app mobile a medida, trabajando con equipos especializados en metodologías Agile/Scrum para tener resultados eficientes':
        'Complete and fully customized development of software soludions and mobile apps, working with Agile/Scrum teams for efficient results',
      'Outsourcing de nuestro staff calificado para integración inmediata con equipos de IT':
        'Outsourcing of our qualified staff members for immediate integration with your IT teams',
      'Por qué elegirnos': 'Why choose us',
      'En Calyx Servicios conocemos el mercado de IT y estamos comprometidos en impulsar continuamente la innovación en software y en facilitar la experiencia de nuestros clientes mediante soluciones de vanguardia.':
        "Calyx Services knows the IT market and we are committed to continually driving innovation in software and facilitating our customers' experience through cutting-edge solutions.",
      'Más': 'More',
      '¿Mas información?': 'More information?',
      'Contactanos': 'Contact Us',
      'Lideramos el cambio de la Digitalización y Automatización':
        'We lead the change in Digitalization and Automation',
      'La transformación digital implica la digitalización y automatización de procesos y tareas, orientada a mejorar la eficiencia de recursos, optimizar los métodos de trabajo, reducir los riesgos y mejorar los servicios prestados a los clientes para generar beneficios económicos medibles y concretos.':
        'Digital transformation involves the digitalization and automation of processes and tasks, aimed at improving resource efficiency, optimizing work methodologies, risk reduction, and improving services provided to clients to generate measurable and concrete economic benefits.',
      'Nuestro enfoque como Partner integral ofrece soluciones para todas las necesidades que involucren una transformación digital, garantizando resultados de forma rápida y segura.':
        'Our approach as a key Partner offers solutions for all your digital transformation needs, guaranteeing results quickly and securely.',
      'Beneficios': 'Advantages',
      'Casos de Aplicación': 'Use Cases',
      'Tecnologías': 'Technologies',
      'Llená nuestro formulario para detectar oportunidades de automatización':
        'Fill out our survey to detect automation opportunities',
      'Compañías increíbles merecen un software increíble con integraciones infinitas.':
        'Amazing companies deserve amazing software with endless integrations.',
      'A través de nuestra Plataforma ERP, vinculamos e integramos todos los procesos de tu negocio. Esto permite potenciar los equipos y minimizar ineficiencias para un funcionamiento óptimo.':
        'Through our ERP Platform, we link and integrate all your business processes. This allows to enhance your teams and minimize inefficiencies for optimal operation.',
      'Consolidar y centralizar el acceso a la información de los distintos equipos reduce barreras y maximiza la interoperabilidad.':
        'Consolidating and centralizing access to information from different teams reduces barriers and maximizes interoperability.',
      'Implementación Personalizada': 'Customized Implementation',
      'Nuestros equipos altamente calificados se especializan en migrar sus procesos de negocio a nuestra plataforma ERP, ofreciendo la localización más completa y funcional del mercado.':
        'Our highly qualified teams specialize in migrating your business processes to our ERP platform, offering the most complete and functional localization on the market.',
      'Desde la carga completa de datos, definición de los workflows e interfaces gráficas, llevamos su empresa a su mejor versión, con herramientas 100% digitales.':
        'From complete data loading, definition of workflows and graphical interfaces, we bring your company to its best version, with 100% digital tools.',
      'Ventajas Odoo': 'Odoo Advantages',
      'Clientes Odoo': 'Odoo Clients',
      'Acceder': 'Sign In',
      'weKnow': `
          <p>We translate business <strong>strategies</strong> into specific digital transformation goals.</p>
          <p>We perform process analysis to detect opportunities for <strong>digitalization and automation</strong>.</p>
          <p>We outline programs and projects as your Business Partners.</p>
          <p>We provide <strong>Agile</strong> work methodology and cutting-edge technologies.</p>
          <p>We estimate the economic, organizational, and cultural benefits of change.</p>
          <p>We set the requirements, <strong>implement and lead</strong> the digital transformation.</p>
          <p>We offer representations and partnerships to enhance the economic gains of <strong>technological change</strong>.</p>
        `,
      'whyUs': `
        <p>
          We offer top-notch services through highly qualified personnel, experts in digital transformation and automation, with solid skills in different languages, which allow us to reach any part of the world. We are a company that stands out for the integration of its collaborators with your IT teams effectively and dynamically.
        </p>
        <p>
          Understanding and seizing these advantages is essential to achieve extraordinary results and to ensure the project success.
        </p>
        <p>
          Today's globalized world allows us to be more efficient in developing IT projects, opening the possibility of working remotely and outsourcing to countries that offer better competitive advantages such as price, technical capabilities, language, flexibility of working hours and ability to integrate.
        </p>
        <p>
          For these reasons and many more, we are your expert Partner for all your IT projects.
        </p>
        `,
      'caseStudies': [
        {
          title: 'BANKING CONCILIATIONS, PAYMENT PLATFORMS AND CREDIT CARDS',
          description:
            "The solution for the client involves the development of an RPA solution for the reconciliation of data that will be extracted through a series of automations to be able to obtain bank statements from different sites or received via email, credit card accounting, transactions in electronic payment platforms, and validating it with the information from the ERP system and the company's point of sale systems. Then, an additional automation is carried out to record everything necessary and emerging from the conciliation process in the accounting and generate reports on the follow-up of differences and claims management to the areas involved.",
          roi: '+400%',
        },
        {
          title: 'AUTOMATION OF THE GENERATION OF MANUAL ACCOUNTING ENTRIES IN ERP',
          description:
            "The developed bot is responsible for automatically downloading reports from the company's inventory management system, processing the data, reconciling them with the stocks entered in the ERP system and based on them, generating the corresponding manual entries in the accounting module of the ERP system of the company.",
          roi: '+480%',
        },
        {
          title: 'AUTOMATION OF ACCOUNTS PAYABLE',
          description:
            "The developed bot is responsible for automatically downloading commercial invoices and delivery receipts from web sites or received via email, obtaining the relevant data through data extraction by OCR, making follow-up reports receipt of invoices / remittances and perform the validation of each invoice against the remittance to verify that the invoice is able to be input into the ERP system. Then the validated vouchers are automatically entered by the bot in the company's ERP detailing the accounting account, the cost center, the amounts received, the agreed value and the payment conditions. The bot also generates a report of differences with the non-reconciled ones and alerts by email to those responsible for managing the process to verify them.",
          roi: '+700%',
        },
        {
          title: 'AUTOMATION OF COLLECTIONS MANAGEMENT',
          description:
            "In this case, the automation of the entire debt collection management process was developed in the pre-judicial stage. This process (core of the business) involved sending communications in 3 stages by 3 different means: First email, second SMS and finally a call, all made manually. The RPA generated the automation of the entire process, taking the information from the client's management system, generating communications via all the mentioned means of communication and WhatsApp technology, leaving only the intervention of people in case of answers or in the final stages of collection management.",
          roi: '+375%',
        },
        {
          title: 'AUTOMATION OF REPORT GENERATION',
          description:
            'Report generation automation was implemented through a bot that integrates the information generated by the ERP consolidating it in the format required by the Headquarters to be reported to another Reporting system. The bot performs the automatic import of the report to the Headquarters system once it is authorized by the Company and sends a presentation report.',
          roi: '+450%',
        },
        {
          title: 'AUTOMATION OF THE EMPLOYEE EXPENDITURE (T&E) PROCESS',
          description:
            'A solution was developed to automate the entire process of reporting employee travel expenses. The solution allows the different collaborators that the company has inside and outside the country (more than 700), to make the entry of their expenses in an agile and simple way. Each expense report goes through several approvals and automatic reviews that are reported to Accounts Payable and Treasury, who already receive the approved expenses to issue payments to each employee. The solution includes the automation of accounting records in the ERP of the expense’s reports.',
          roi: '+700%',
        },
        {
          title: 'AUTOMATION OF HR PROCESSES',
          description:
            'A solution was developed for the client to automate and systematize several of the HR processes: absenteeism analysis, request for licenses, management of vacation grants, generation and sending of salary receipts with electronic signature of employees, performance evaluations, delivery, and validation of objectives.',
          roi: '+850%',
        },
      ],
      // Digital
      'Comenzar': 'Start',
      'digitalAdvantages': `
        <ul>  
          <li>In 100% of the cases of RPA implementations our clients had a Return on Investment of <strong>more than 200%.</strong></li>
          <li>The investment recovery time does not exceed <strong>6 to 8 months</strong>.</li>
          <li>The average cost savings of this technology equates to <strong>65% of the labor cost being replaced</strong>.</li>
          <li>The productivity of our clients' work teams increased <strong>more than 77%</strong>.</li>
          <li>The risks of errors decreased on average <strong>by 83%</strong>.</li>
          <li>In <strong>100% of the cases</strong>, the fulfillment times of the tasks or processes have been improved.</li>
          <li>Robots never sleep, they do not make mistakes, and they are much less expensive per man-hour worked.</li>
        </ul>
      `,
      'digitalCases': [
        {
          title: 'FINANCE AND ACCOUNTING',
          icon: chart,
          content: `
          <ul>
            <li>Reporting and consolidation</li>
            <li>Data entry of accounting entries</li>
            <li>Bank and accounting reconciliations</li>
            <li>ABM databases</li>
            <li>Cash flow</li>
            <li>Fixed asset management</li>
            <li>Reports in Power BI</li>
          </ul>`,
        },
        {
          title: 'TAX SETTLEMENT',
          icon: taxes,
          content: `
          <ul>
            <li>Generation of tax settlements</li>
            <li>Presentation of the affidavits at the Collection Agency</li>
            <li>Compliance check list with recurring tax obligations</li>
            <li>Generation of electronic address news</li>
            <li>Updating of customer and supplier tax databases</li>
            <li>Control of supplier databases and apocryphal base documents</li>
          </ul>`,
        },
        {
          title: 'PURCHASES AND INVENTORIES',
          icon: inventory,
          content: `
          <ul>
            <li>Purchase order generation</li>
            <li>Data entry of invoices, remittances and payments</li>
            <li>Bid Analysis</li>
            <li>Purchase and inventory planning</li>
            <li>Returns management</li>
            <li>Price list import</li>
            <li>Inventory logistics management</li>
          </ul>`,
        },
        {
          title: 'SALES AND COLLECTIONS',
          icon: currency,
          content: `
          <ul>
            <li>Opportunity generation</li>
            <li>Order generation</li>
            <li>Billing Generation</li>
            <li>Customer Service Chat bots</li>
            <li>ABM customer base</li>
            <li>Calculation of sales commissions</li>
            <li>Delivery routes generation</li>
            <li>Reporting in Power BI</li>
            <li>Data Analytics for marketing actions</li>
          </ul>`,
        },
        {
          title: 'IT',
          icon: dev,
          content: `
          <ul>
            <li>Server monitoring</li>
            <li>Synchronization, deletion and emptying of folders</li>
            <li>Level 1 support with chatbots</li>
            <li>Batch processes</li>
            <li>FTP download, upload and support</li>
            <li>Tests</li>
          </ul>`,
        },
        {
          title: 'HUMAN RESOURCES',
          icon: human,
          content: `<ul>
            <li>News management</li>
            <li>Recruiting process</li>
            <li>Vacation and leave management</li>
            <li>Management of salary receipts</li>
            <li>Payroll settlement</li>
            <li>Onboarding</li>
            <li>Help desk frequently asked questions</li>
          </ul>`,
        },
      ],
      // Erp
      'erpAdvantages': [
        {
          icon: currency,
          title: 'Control and Reduce Costs',
          content: `
          <ul>
            <li>Zero infrastructure</li>
            <li>No hidden costs</li>
            <li>Pay for what you use (users / apps)</li>
          </ul>
        `,
        },
        {
          icon: puzzle,
          title: 'Complete and Integrated Features',
          content: `
          <ul>
            <li>Simple integration</li>
            <li>An app for every business need</li>
            <li>Scale your software at any time</li>
          </ul>
        `,
        },
        {
          icon: tv,
          title: 'Friendly User Interface',
          content: `
          <ul>
            <li>Always accessible</li>
            <li>Frequent updates</li>
            <li>Compatible on various devices</li>
          </ul>
        `,
        },
        {
          icon: speedometer,
          title: 'Rapid Implementation',
          content: `
          <ul>
            <li>Instant access to new software</li>
            <li>Short deployments</li>
            <li>Quick adoption by employees</li>
          </ul>
        `,
        },
        {
          icon: lock,
          title: 'Secure',
          content: `
          <ul>
            <li>SLA: 99.9% guaranteed</li>
            <li>Security updates</li>
            <li>Daily backups in 3 data centers</li>
          </ul>
        `,
        },
        {
          icon: edit,
          title: 'Customizable',
          content: `
          <ul>
            <li>No programming knowledge required</li>
            <li>Customize fields, views, and processes</li>
            <li>Technical flexibility</li>
          </ul>
        `,
        },
      ],
      'AUMENTE SUS VENTAS': 'Increase your sales',
      'Ventas': 'Sales',
      'INTEGRE SUS SERVICIOS': 'Service Integration',
      'Proyecto': 'Project',
      'Hoja de Horas': 'Timesheet',
      'Servicio de Asistencia': 'Assistance',
      'OPTIMICE SUS OPERACIONES': 'Optimize Operations',
      'Inventario': 'Inventory',
      'Compra': 'Purchases',
      'CONSTRUYA SITIOS WEB IMPACTANTES': 'Build Amazing Websites',
      'Creador de Sitios Web': 'Site Builder',
      'Comercio Electrónico': 'E-Commerce',
      'MANEJE SUS FINANZAS': 'Finance Management',
      'Facturación': 'Billing',
      'Contabilidad': 'Accounting',
      'AMPLIFIQUE SU MARKETING': 'Amplify your Marketing',
      'Marketing por E-mail': 'E-mail Marketing',
      'Automatización de Marketing': 'Marketing Automation',
      'PERSONALICE Y DESARROLLE': 'Personalize and Develop',
      // Software
      'Tu socio estratégico': 'Your strategic partner',
      'Nos especializamos en el desarrollo integral de <b>soluciones tecnológicas personalizadas</b> y adaptadas a las necesidades del negocio.':
        'We specialize in the comprehensive development of customized technological solutions adapted to business needs.',
      'Nuestra identidad digital innata impulsa la transformación en todas las industrias.':
        'Our innate digital identity drives transformation across industries.',
      'Siempre estamos listos para potenciar un cambio significativo con una visión estratégica hacia el futuro.':
        'We are always ready to drive significant change with a strategic vision for the future.',
      'Desarrollo Integral': 'Integral Development',
      'Trabajamos con nuestros clientes en la implementación completa de la solución óptima.':
        'We work with our clients on the full implementation of the best solution.',
      'Desde la definición de los requerimientos, al diseño de bases de datos y API, hasta aplicaciones e interfaces mobile, web y IoT.':
        'From the definition of requirements, to the design of databases and APIs, to applications and mobile, web and IoT interfaces.',
      'Todos nuestros desarrollos incluyen testeo de calidad, DevOps y deployments en los principales clouds.':
        'All our developments include quality testing, DevOps and deployments in the main clouds.',
      'Nuestras Virtudes': 'Our Strengths',
      'softwareItems': [
        {
          title: 'Quality',
          icon: quality,
          content: '<p><br/>Highly qualified, creative IT staff with vast experience in high impact projects</p>',
        },
        {
          title: 'Communication',
          icon: humanGreetingProximity,
          content: '<p><br/>Profficiency in Spanish and English for international projects</p>',
        },
        {
          title: 'Collaboration',
          icon: humanCapacityIncrease,
          content: '<p><br/>We add quality work to your teams in a concrete and efficient way</p>',
        },
        {
          title: 'Focus',
          icon: bullseyeArrow,
          content: '<p><br/>Objective and result-oriented developers and team leaders</p>',
        },
        {
          title: 'Methodology',
          icon: webhookIcon,
          content: '<p><br/>Team of Project Managers specialized in Scrum / Agile methodology</p>',
        },
        {
          title: 'Flexibility',
          icon: flex,
          content: '<p><br/>Dynamic and customizable team to the needs of the project</p>',
        },
      ],
      'Lenguajes': 'Languages',
      // Staff Augmentation
      'Aportamos talento a tus equipos de IT para potenciar tus proyectos':
        'We bring talent to your IT teams to enhance your projects',
      'Sabemos que incorporar personal de IT a su organización es un factor clave, y contar con recursos técnicos expertos se ha convertido en un objetivo cada vez más complejo.':
        'We know that incorporating IT staff into your organization is a key factor and having experienced resources has become an increasingly complex objective.',
      'Para ello, brindamos todo el soporte y know how ofreciendo flexibilidad y capacidad de adaptación rápida con procesos de desarrollo más efectivos y en menos tiempo, a través de profesionales adecuados para tus proyectos y especializados en las últimas tecnologías.':
        'To achieve that, we provide all the support and know-how offering flexibility and the ability to adapt quickly with more effective development processes and in less time, through professionals who are suitable for your projects and are specialized in the latest technologies.',
      '¿Cómo reclutamos?': 'HOW WE RECRUIT',
      'Nuestro equipo de Recruiting a través de una metodología 100% probada, garantiza que los recursos cuenten con las <strong>validaciones técnicas y habilidades</strong> blandas necesarias para el desenvolvimiento en equipos de IT de forma inmediata. Testeamos rigurosamente las aptitudes de razonamiento lógico / matemático, la capacidad técnica y las competencias personales en nuestro proceso de entrevistas y sólo se presentan a nuestros clientes aquellos profesionales con <strong>mejores puntuaciones</strong>.':
        'Our Recruiting team through a 100% proven methodology, guarantees that the resources have the <strong>technical validations and skills</strong> necessary for the development in IT teams immediately. We rigorously test logical / mathematical reasoning skills, technical ability, and personal competencies in our interview process and only those professionals with the <strong>best scores</strong> are presented to our clients.',
      'Contamos con los mejores bancos de datos para ofrecer los <strong>perfiles más sofisticados</strong> en un mercado dinámico con un equipo de Recruiting conectado 24/7.':
        'We have the best databases to offer the most sophisticated profiles in a dynamic market with a Recruiting team connected 24/7.',
      'Ventajas': 'Advantages',
      'loanItems': [
        {
          icon: clock,
          title: 'Time Saving',
          content:
            '<p><br/>Meet your deadlines and put our hiring process to work 24 hours a day for your team. Find the right professionals for each position in less time.</p>',
        },
        {
          icon: human,
          title: 'Capacity Increase',
          content:
            '<p><br/>Quickly integrate highly qualified professionals who increase the capacity of your team and add value to your project.</p>',
        },
        {
          icon: vertical,
          title: 'Instant Technical Insights',
          content: '<p><br/>Minimize project downtime with the expertise of an extended team.</p>',
        },
        {
          icon: horizontal,
          title: 'Flexibility',
          content:
            '<p><br/>Increase or decrease the size of your development workforce as needed with talent on demand.</p>',
        },
      ],
      // Company
      'Nuestros Especialistas': 'Our Specialists',
      'about': `
        <p>
          We are part of the <a>PGK Consulting Group</p>, an integrated results-driven consulting firm with solid experience and over 170 professionals.
        </p>
        <p>
          For 15 years we have delivered top quality services through our qualified, creative and highly experienced IT staff. At Calyx you will find specialists always at your disposal to start developing projects.
        </p>
        <p>
          We understand our client's needs and suit to them. We are flexible, support our clients from the beginning of the projects and provide them the appropriate advice.
        </p>
        <p>
          Thanks to our proficiency in Spanish and English, we have the capacity to develop international projects, providing services to clients based around the word, meeting the needs of small and big companies in all industries.
        </p>
        <p>
          We have more than 150 clients in the market accross industries.
        </p>
      `,
      'team': `
        <p>
          From Tech Leads to QA Engineers and Project Managers, our teams are made up of experts in all fields, capable of meeting your specific needs. We can provide all the necessary roles to make your project come true. We have the technical knowledge to quickly identify a winning roadmap for your purpose, managing the best talent to add value every step of the way.
        </p>
        <p>
          We have dedicated project managers who closely follow up on each proposal focusing on KPIs, and comprehensive internal processes that ensure our teams always remain engaged and productive.
        </p>
        <p>
          Our staff includes multidisciplinary teams composed of experts: Project Managers, Technical Leaders, Automation Developers, Architects, SF Engineers, DEVOPS, Odoo Developers and Functional Analysts, among others.
        </p>
      `,
      'reynaldoTitle': 'Technology Consulting Leader',
      'reynaldoSummary':
        'Reynaldo has more than 10 years of experience in the software industry working for clients from different industries: Mass Consumption, Retail, Utilities, Energy and Private Banking. He joined Calyx as a Partner since its inception, and since then he is responsible for the Digital Transformation & Software Factory areas, leading highly complex development and consulting projects, for clients in Argentina and Colombia, Spain, the United States, among others. His passion for leading new technology challenges and improving the user experience is the guide of the Calyx team.',
      'reynaldoDescription': `
        <p>
          He is a Computer Engineer graduated from the Universidad Argentina de la Empresa, in Buenos Aires. He also obtained important certifications such as Scrum Foundation Professional Certificate and has extensive experience in handling cutting-edge technologies such as Python, Angular, MySQL and Microsoft SQL Server.
        </p>
        <br />
        <p>
          He is currently the Technology Consulting Leader at Calyx and is in charge of important clients from different industries such as Unilever, Banco Provincia, Banco Hipotecario, HSBC, Edesur, PAE Energy, Red Link, Green EAT, Tea Connection, Bacardi, DARC Peugeot / Citroen, Maynar Volkswagen, SKF, Grupo Cepas, AVON, Etilfarma, Talleres Sustentables, among others.
        </p>
      `,
      'Política de Calidad': 'Quality policy',
      politicaCalidad: `
      <p>At Calyx, a company dedicated to the design, development, and implementation of technical and automation solutions, our goal is to understand our clients' business and thus design and implement high-value-added automation and digital transformation solutions that contribute to achieving their objectives.</p>
      <p>We are committed to continuously improving the effectiveness of our Quality Management System based on the current ISO 9001 International Standard, and we aim to:</p>
      <p>Focus on the client as a business partner, understanding their needs and adapting to them to meet their needs and expectations.</p>
      <p>Promote a culture of risk management, quality, and process improvement among all company personnel, striving for the continuous improvement of our QMS.</p>
      <p>High-performance talent teams, ensuring a work environment that fosters motivation, participation, and professional development through training and the use of cutting-edge tools.</p>
      <p>Comply with the legal and regulatory requirements that apply to us.</p>
      <p>Meet the requirements of our stakeholders.</p>
      `,
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Cache)
  .init({
    fallbackLng: 'es',
    Cache: {
      enabled: false,
      prefix: 'translation_',
      expirationTime: Infinity,
      Version: {},
    },
    resources,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
