import React from 'react';
import Util from '../Util';

const SplitSection = ({ id, primarySlot, secondarySlot, reverseOrder, children, alt, style }) => (
  <section
    id={id}
    style={{
      padding: Util.isMobile() ? '40px 0px' : '100px 0px',
      ...style,
    }}
  >
    <div className="container mx-auto items-center flex flex-col lg:flex-row">
      <div className="flex-1">{primarySlot}</div>
      {secondarySlot && (
        <div className={`mt-10 flex-1 ${reverseOrder && `order-last lg:order-first`}`}>{secondarySlot}</div>
      )}
    </div>
    {children}
  </section>
);

export default SplitSection;
